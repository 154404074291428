/* eslint-disable jsx-a11y/iframe-has-title */
import { useState, useEffect } from "react";
import { Card, Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "../../views/general/generaltab.scss";
import i18n from "i18next";
import MyGroups from "./MyGroups";
import editGroup from "./edit-group";
import JoinGroup from "./JoinGroup";
import EditGroupComponent from "./edit-group/EditGroupComponent";
import BackButton from "../commons/BackButton";

const GroupContainer = (props) => {
  const { t } = useTranslation();
  const language = i18n.language.split("-")[0];

  const [key, setKey] = useState("MY_GROUPS");

  const handleTabSelect = (param) => {
    setKey(param);
  };

  return (
    <>
      <div className="container">
        {/* <div class="back-btn-cont">
          <BackButton />
        </div> */}
        <Card className="gdpr-card custom-group-card">
          <Card.Body>
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => handleTabSelect(k)}
              unmountOnExit={true}  
              justify 
              className="tab-content"

            >
              <Tab
                eventKey="MY_GROUPS"
                title={t("my_groups")}  
              >
                <MyGroups {...props} />
              </Tab>
              <Tab
                eventKey="CREATE_GROUP"
                title={t("add_edit_group_create")}
              >
                <EditGroupComponent {...props} />
              </Tab>
              <Tab
                eventKey="JOIN_GROUP"
                title={t("join_group")}
              >
                <JoinGroup {...props} />
              </Tab>
            </Tabs>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default GroupContainer;
