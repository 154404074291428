import React, { useEffect, useState } from "react";
import { useNavigate, Redirect } from "react-router-dom";
import "./Login.scss";
import Button from "../../components/elements/buttons/Button";
import { Form, Modal, InputGroup } from "react-bootstrap";
import { loginUser, loginUserWithFacebook, sendEmail } from "lib/context/authActions";
import { useAuthDispatch } from "lib/context/authContext";
import IconLink from "../../components/elements/buttons/IconLink";
import { useTranslation } from "react-i18next";
import CompleteData from "./CompleteData";
import { checkGroupCode } from "../../api/groups";
import IconButton from "../../components/elements/buttons/IconButton";
import {  validateEmail } from "lib/utils/Commons";
// import { getShowRegionals } from "../../redux/actions";
import { Visibility, VisibilityOff } from '@mui/icons-material';


function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [completeDataToken, setCompleteDataToken] = useState(null);
  const [completeDataFb, setCompleteDataFb] = useState(null);
  const [showModal, setModal] = useState(false);
  const [showErrorModal, setErrorModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [inactiveUserModal, setInactiveUserModal] = useState(false)

  const dispatch = useAuthDispatch();
  const history = useNavigate();
  const { t, i18n } = useTranslation();

  // useEffect(() => {
  //   const groupCode = localStorage.getItem("groupCode");
  //   if (groupCode) {
  //     checkGroupCode(null, groupCode).then((res) => {
  //       if (res.data.OK) {
  //         const groupId = res.data.OK;
  //         const encodeInviteCode = encodeURIComponent(groupCode);
  //         return history(
  //           `/group/unauth/${groupId}/about?gid=${encodeInviteCode}`
  //         );

  //         // <Redirect to={{path:`/group/unauth/${groupId}/about?gid=${encodeInviteCode}`}}/>
  //       }
  //     });
  //   }
  // }, []);

  const validateForm = async (e) => {
    e.preventDefault();
    setMessage("");
    if (!validateEmail(email.trim())) {
      setMessage("not_valid_email");
      setErrorModal(true);
    } else if (password.length < 8) {
      setMessage("password_short_error_msg");
      setErrorModal(true);
    } else {
      try {

        let res = await loginUser(dispatch, { email, password });

        if (res.Active === "n") {
          setInactiveUserModal(true)
          setMessage(res.ER.includes("Login failed") ? 'login_failed_error_msg' : res.ER);
          return
        }
        if (res.ER) {
          setMessage(res.ER.includes("Login failed") ? 'login_failed_error_msg' : res.ER);
          setErrorModal(true);
        } else {
          console.log("login user", res);
          // getShowRegionals(res.TMBP_ACC_TOKEN)

          if (!res.Language || !res.State || !res.Plz || res.Plz === "0") {
            console.log('called.....');
            setCompleteDataToken(res.TMBP_ACC_TOKEN);
          } else {
            // history("/");
            const groupCode = localStorage.getItem("groupCode");
            if (groupCode) {
              checkGroupCode(res.TMBP_ACC_TOKEN, groupCode).then((res) => {
                if (res.data.OK) {
                  const groupId = res.data.OK;
                  const encodeInviteCode = encodeURIComponent(groupCode);
                  localStorage.removeItem("groupCode");
                  return history(
                    `/group/${groupId}/about?gid=${encodeInviteCode}`
                  );
                } else {
                  return history(`/`);
                }
              });
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const responseFacebook = async (response) => {
    if (response.accessToken) {
      try {
        let res = await loginUserWithFacebook(dispatch, response);
        if (res.ER) setMessage(res.ER);
        else if (!res.data.Language || !res.data.State || !res.data.Plz) {
          setCompleteDataToken(res.TMBP_ACC_TOKEN);
          setCompleteDataFb(res);
        } else history("/");
      } catch (error) {
        console.log(error);
      }
    }
  };

  const reSendEmail = async () => {

    try {
      let res = await sendEmail(email)
      if (res.OK) {
        setErrorModal(true)
        setMessage(res.OK)
      } else if (res.ER) {
        setErrorModal(true)
        setMessage(res.ER)
      }
    } catch (error) {
      console.log(error);

    }
    finally {
      setInactiveUserModal(false)
    }
  }


  return (
    <>
      {completeDataToken && (
        <CompleteData token={completeDataToken} fb={completeDataFb} />
      )}
      {!completeDataToken && (
        <div className="loginPage">
          <div className="loginBox">
            <Form className="loginForm" onSubmit={validateForm}>
              <Form.Group size="lg" controlId="email">
                <Form.Label>{t("email")}</Form.Label>
                <Form.Control
                  autoFocus
                  type="text"
                  placeholder={t("login_email_placeholder")}
                  required
                  value={email}
                  autoComplete='off'
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Form.Text className="text-muted">
                  {t("login_email_description")}
                </Form.Text>
              </Form.Group>
              
              <Form.Group size="lg" controlId="password" className="mb-30 position-relative">
                <Form.Label>{t("password")}</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    placeholder={t("login_password_placeholder")}
                    onChange={(e) => setPassword(e.target.value)}
                    style={{ paddingRight: "40px" }}
                  />
                  <InputGroup.Text className="bg-transparent border-0 p-0">
                    <IconButton
                      handleClick={() => setShowPassword(!showPassword)}
                      icon={showPassword ? <Visibility color="#00395c" /> : <VisibilityOff color="#00395c" /> }
                      styles={{ boxShadow: "none", zIndex: 9 }}
                      className="p-0 text-dark position-absolute top-50 translate-middle-y end-0 bg-transparent border-0"
                    />
                  </InputGroup.Text>
                </InputGroup>
                <Form.Text className="text-muted">
                  {t("login_password_description")}
                </Form.Text>
              </Form.Group>

              <Button name="login" text={t("login")} type="submit" className="w-100" />
            </Form>

            {/* <span className="or-btn">or</span> */}
            {/* <FacebookLogin
              appId="108212629859643"
              fields="id,first_name,last_name,email,picture"
              callback={responseFacebook}
              render={(renderProps) => (
                <Button
                  text={t('facebook_info')}
                  type="button"
                  className="w-100 fb-btn"
                  handleClick={renderProps.onClick}
                />
              )}
            /> */}

            <IconLink
              link="/reset-password"
              text={t("lost_password")}
              className="w-100 mt-30 justify-content-center"
              clear={true}
            />
            <span className="or-btn mt-30">{t("login_info_reg")}</span>

            <IconLink
              name="register"
              link="/register"
              text={t("register")}
              className="w-100 link-btn inverted justify-content-center"
            />
          </div>
        </div>
      )}

      <Modal
        show={showModal}
        onHide={() => setModal(false)}
        contentClassName="box"
        centered
      >
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Body>
          <div>{t("logout_grp_join_mess")}</div>

          <div className="float-right pt-3">
            <Button text={t("ok")} handleClick={() => setModal(false)} />
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={inactiveUserModal}
        onHide={() => setInactiveUserModal(false)}
        contentClassName="box"

        className="custom-modal"
      >
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Body>
          <IconButton
            clear="true"
            className="closeIcon"
            icon="close"
            handleClick={() => setInactiveUserModal(false)}
          />
          <div>{t(message)}</div>

          <div className="float-left pt-3">
            <Button text={t("cancel_alert")} handleClick={() => setInactiveUserModal(false)} />
          </div>
          <div className="float-right pt-3">
            <Button text={`${t("send_button")} ${t("email")}`} handleClick={() => reSendEmail()} />
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showErrorModal}
        onHide={() => setErrorModal(false)}
        className="custom-modal"
      >
        <IconButton
          clear="true"
          className="closeIcon"
          icon="close"
          handleClick={() => setErrorModal(false)}
        />
        <div >{t(message)}</div>
      </Modal>
    </>
  );
}

export default Login;
