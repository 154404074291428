import DeleteIcon from "@mui/icons-material/Delete";
import { Avatar, IconButton, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { useState } from "react";
import CommentDeleteDialog from "../../posts/commentDeleteDialog/commentDeleteDialog";
import TwitterTextView from "components/commons/TwitterTextView";
import {TwitterTextStyles} from "../style";

const PostCommentSingle = (props: {
  comment: any;
  index: number;
  deleteComment: (index: number) => void;
  onUserImgClick: (userId: string) => void;
  userDetailsAction: (id: number, post?: any) => void;
  groupInfoAction: (id: string) => void;
}) => {
  const comment = props.comment;
  const [showDeleteAlert, setDeleteAlertVisible] = useState(false);

  function onCommentDelete() {
    props.deleteComment(props.index);
  }

  function confirmDelete() {
    setDeleteAlertVisible(true);
  }

  const onPressMention = (e: React.MouseEvent<HTMLDivElement, MouseEvent> | any, mention: any) => {
    if (mention.type == "group") {
      props.groupInfoAction(mention.ID as string);
    } else if(mention.type == "user") {
      props.userDetailsAction(mention.ID as any, {FK_userID: mention.ID, loci_postID: null} as any);
    }
  };

  return (
    <ListItem
      key={comment.loci_commentID}
      alignItems='flex-start'
      sx={{ 
        paddingLeft: 0,
        "& .MuiListItemSecondaryAction-root": {
          top: "10px",
          transform: "translateY(0%)"
        }
      }}
      secondaryAction={
        comment.my != "n" ? (
          <IconButton edge='end' aria-label='delete'  onClick={confirmDelete}>
            <DeleteIcon sx={{ width:"20px" }} />
          </IconButton>
        ) : null
      }
    >
      <ListItemAvatar>
        <Avatar
          variant='rounded'
          alt={comment.USE_firstname + " " + comment.USE_surename}
          onClick={(e) => onPressMention(e, {ID: comment.FK_userID, type:"user"})}
          style={{cursor: "pointer"}}
          sx={{width:"35px!important", height:"35px!important", borderRadius:"12px"}}
          src={
            comment.USE_pic
              ? comment.USE_pic
              : comment.LCU_fbpic
                ? comment.LCU_fbpic
                : "/images/profile.png"
          }
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            <a onClick={(e) => onPressMention(e, {ID: comment.FK_userID, type:"user"})}>
              <Typography component="span" variant="body1" style={{ display: "block", fontSize: "0.875rem", color: "#97b838" }}>
                {comment.USE_firstname + " " + comment.USE_surename}
              </Typography>
            </a>
            <Typography component="span" variant="body2" style={{ display: "block", fontSize: "0.75rem", color: "rgba(0, 0, 0, 0.6)" }}>
              {comment.LOC_date}
            </Typography>
          </>
        }
        // secondary={comment.LOC_text}
        secondary={
          <>
            <TwitterTextView
              className='mb-3'
              style={{overflowX:"hidden", whiteSpace: "pre-wrap"}}
              hashtagStyle={TwitterTextStyles.hashtagStyle}
              mentionStyle={TwitterTextStyles.mentionStyle}
              linkStyle={TwitterTextStyles.linkStyle}
              emailStyle={null}
              onPressLink={undefined}
              onPressHashtag={undefined}
              extractHashtags={undefined}
              extractMentions={true}
              onPressMention={onPressMention}
              extractLinks={undefined}
              extractEmails={undefined}
              onPressEmail={undefined}
              mentionData={comment.mentions}
            >
              {comment.LOC_text}
            </TwitterTextView>
          </>
        }
        secondaryTypographyProps={{ style: { textAlign: "left", margin: "10px 0 0", wordWrap: "break-word" } }} 
      />
      {showDeleteAlert && (
        <CommentDeleteDialog
          deleting={true}
          handleClose={() => setDeleteAlertVisible(false)}
          onSubmit={() => {
            onCommentDelete();
          }}
        />
      )}
    </ListItem>
  );
};

export default PostCommentSingle;
