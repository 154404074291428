/* eslint-disable quotes */
import { ThemeProvider } from "styled-components";

export type ITheme = {
  fontFamily: {
    primary: string;
    secondary: string;
  };
  colors: {
    primary: string;
    secondary: string;

    palmLeaf: string;
    gray: string;

    danger: string;
    warning: string;
    success: string;
    error: string;

    body: string;
    text: string;
    input: string;
    white: string;
    black: string;

    disabled: string;
  };
  mediaQueries: {
    phone: string;
    tabletMin: string;
    tablet: string;
    desktop: string;
  };
  spacing: {
    "1": string;
    "2": string;
    "3": string;
    "4": string;
    "5": string;
    "6": string;
    "7": string;
    "8": string;
    "9": string;
    "10": string;
    "11": string;
    "12": string;
    "14": string;
    "16": string;
  };
  transition: {
    default: string;
  };
};

type ThemeProps = {
  children: JSX.Element;
};

const theme: ITheme = {
  fontFamily: {
    primary: '"Inter", sans-serif',
    secondary: '"Roboto", sans-serif',
  },
  colors: {
    primary: "#95B83C",
    secondary: "#00395c",

    palmLeaf: "#819841",
    gray: "#9E9E9E",

    danger: "#FF6D64",
    warning: "#f28f26",
    success: "#85DFAE",
    error: "#FF0000",

    body: "#F3F3F3",
    text: "#484848",
    input: "#eee",
    white: "#fff",
    black: "#000",

    disabled: "#e9e9e9",
  },
  mediaQueries: {
    phone: "only screen and (max-width: 768px)",
    tabletMin: "only screen and (min-width: 769px) and (max-width: 990px)",
    tablet: "only screen and (min-width: 991px) and (max-width: 1024px)",
    desktop: "only screen and (min-width: 1280px)",
  },
  spacing: {
    "1": "2px",
    "2": "4px",
    "3": "6px",
    "4": "8px",
    "5": "10px",
    "6": "12px",
    "7": "14px",
    "8": "16px",
    "9": "18px",
    "10": "20px",
    "11": "22px",
    "12": "24px",
    "14": "28px",
    "16": "32px",
  },
  transition: {
    default: "all 0.3s ease-in-out",
  },
};

const Theme = ({ children }: ThemeProps): JSX.Element => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;
