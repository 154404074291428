import React, { Component, useState } from 'react';
import Button from "../elements/buttons/Button";
import "./Footer.scss";
import { footer_menu } from './menu';
import IconLink from '../elements/buttons/IconLink';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t, i18n } = useTranslation();
    return( 
      <>
      <div className="site-footer">
          <div className="container">
            <div className="content-wrapper">
                <div className="footer-links">
                  <ul>
                  {footer_menu.map((item, i) => (
                      <li key={i}>
                          {item.name==='Over_us' ?
                          <a href={item.link} id="Over_us" target="_blank"> {t(item.name)} </a>:
                        <Link to={{pathname:item.link}}>
                          {t(item.name)} 
                        </Link>
}
                      </li>
                    ))}
                  </ul>
                </div>

                <span className="footer-copyright">{t('rights')}</span>
              {/* <div className="footer-images">
                  <a href={t('footer_ios')} target="_blank" id="IosFooter">
                    <img src="/images/AppStore1.png" />
                  </a>
                  <a href={t('footer_android')} target="_blank" id="AndroidFooter">
                    <img className="middle"  src="/images/GooglePlay1.png" />
                  </a>
                  <a href="https://www.ssl-zertifikate.de/" target="_blank">
                  <img src="/images/ssl-secure.png" />
                  </a>
                  
              </div> */}
            </div>

          </div>

      </div>
    </>
      )    
}

export default Footer;

