import React, { Component } from "react";
import { Form, Modal } from "react-bootstrap";
import { getGroupSettings, getSettings, saveGroupSettings, saveSettings } from "../../api/user";
import Button from "../elements/buttons/Button";
import "react-datepicker/dist/react-datepicker.css";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
// import "./GroupSettings.scss";
import moment from "moment";
import Checkbox from "../elements/checkbox";
import { withTranslation } from "react-i18next";
import BackButton from "../commons/BackButton";
import IconButton from "../elements/buttons/IconButton";
import { withRouter } from "../customNavigate/withRouter";
import Loader from "../../components/posts/single-post/PostLoader/postLoader.js";
import { dateFormatter } from "lib/utils/Commons";
import GroupCard from "../commons/GroupCard";



class GroupSettings extends Component {
  state = {
    response: null,
    showModal: false,
  };

  constructor(props) {
    super(props);

  }

  state={
    group:{}
  }

  componentDidMount() {
    if (this.props.token) {


      const id = this.props.match.params.id

      this.getData();
    }
  }

  closeModal() {
    this.setState({
      showModal: !this.state.showModal,
    });
  }

  getData() {
    console.log('called2');

    getGroupSettings(this.props.token, this.props.match.params.id).then((res) => {
      if (res.data.OK) {
        const data = res.data.OK;
        this.setState({
          group:data
        })
        this.setState({
          isNewFromFav: data.gcm_favpost == "y",
          isCommentFromFavPosts: data.gcm_favcomment == "y",
          isNewLike: data.gcm_like == "y",
          isNewFollower: data.gcm_follow === "y",
          isNewMember: data.gcm_member === "y",
          isPostFromRegion: data.gcm_post === "y",


        });
      }
    });
  }

  // getGroupType(group) {
  //   const { t } = this.props;

  //   if(group?.community==="y") {
  //     return t("community_feed")
  //   }
  //   else if(group?.public==="y") {
  //     return t("public_grp")
  //   }
  //   else if(group?.public==="n") {
  //     return t("private_grp")
  //   }
  //   else {
  //     return t("public_feed")
  //   }



  // }

  submitSettingsForm(e) {
    e.preventDefault();
    const { t } = this.props;

    saveGroupSettings(this.props.token, this.props.match.params.id,this.state,'grpSetting').then((res) => {
      if (res.data.OK) {
        this.setState({
          showModal: true,
          response: t("group-setting-save-msg"),
          editing: false,
        });
      } else {
        this.setState({
          showModal: true,
          response: res.data.ER,
        });
      }
    });
  }

  render() {
    const group=this.state.group
    const { t } = this.props;
    const editing = this.state.editing;
    const data = this.state.profile;
    const birthDate =
      this.state.birthday !== "0000-00-00"
        ? moment(this.state.birthday, "YYYY-MM-DD").toDate()
        : moment.now();

    const states = this.state.countries;
    const languages = this.state.languages;

    const myLang = this.state.myLang;
    const myState = this.state.myState;
    return (   

      !this.state.group.hasOwnProperty('LGP_name') ?
    <Loader />
    :
     <>
        <div className="my-settings main-content">
          <div className="main-content-narrow">
            <div className="pt-3 pb-1">
              <BackButton />
            </div>
            <GroupCard group={this.state.group}/>
            <div className="my-settings-box">
              <div className="my-settings-content">
                <Form
                  className="settingsForm"
                  onSubmit={(e) => this.submitSettingsForm(e)}
                >
                  <h2 className="m-0">
                    {t("settings_title_push_notifications")}
                  </h2>
                  <Form.Group size="lg"  className="form-group" controlId="isCommentFromFavPosts">
                    <Checkbox
                      text={t("settings_option_favorites_post_comment")}
                      value={this.state.isCommentFromFavPosts}
                      handleChange={(e) =>
                        this.setState({
                          isCommentFromFavPosts:
                            !this.state.isCommentFromFavPosts,
                        })
                      }
                    />
                  </Form.Group>

                  <Form.Group size="lg" className="form-group" controlId="isNewLike">
                    <Checkbox
                      text={t("settings_option_message_like")}
                      value={this.state.isNewLike}
                      handleChange={(e) =>
                        this.setState({ isNewLike: !this.state.isNewLike })
                      }
                    />
                  </Form.Group>



                  <Form.Group size="lg" className="form-group" controlId="isNewFollower">
                    <Checkbox
                      text={t("settings_option_followers")}
                      value={this.state.isNewFollower}
                      handleChange={(e) =>
                        this.setState({
                          isNewFollower: !this.state.isNewFollower,
                        })
                      }
                    />
                  </Form.Group>

                 

                  <Form.Group size="lg" className="form-group" controlId="isNewMember">
                    <Checkbox
                      text={t("settings_option_group_members")}
                      value={this.state.isNewMember}
                      handleChange={(e) =>
                        this.setState({ isNewMember: !this.state.isNewMember })
                      }
                    />
                  </Form.Group>

                  <Form.Group size="lg" className="form-group" controlId="isNewPostInGroup">
                    <Checkbox
                      text={t("settings_option_group_post")}
                      value={this.state.isPostFromRegion}
                      handleChange={(e) =>
                        this.setState({
                          isPostFromRegion: !this.state.isPostFromRegion,
                        })
                      }
                    />
                  </Form.Group>

                  <div className="settings-actions">
                    <Button type="submit" text={t("save")} />
                  </div>
                </Form>
              </div>
            </div>
          </div>
          <Modal
            show={this.state.showModal}
            onHide={() => this.closeModal()}
            className="custom-modal"
          >
            <IconButton
              clear="true"
              className="closeIcon"
              icon="close"
              handleClick={() => this.closeModal()}
            />

            {t(this.state.response)}
          </Modal>
        </div>
      </>
    );
  }
}

export default withTranslation()(withRouter(GroupSettings));
