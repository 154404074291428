import React, { Component, useState, useEffect } from "react";
import { getJoinRequestsForGroup, confirmPendingUser, declinePendingUser } from "../../api/groups";
import IconLink from "../elements/buttons/IconLink";
import Button from "../elements/buttons/Button";
import UserCard from "../elements/cards/UserCard";
import UserData from "../elements/cards/UserData";
import { withRouter } from "../customNavigate/withRouter";
import IconButton from "../elements/buttons/IconButton";
import GroupPortal from "./GroupPortal";
import { withTranslation } from 'react-i18next';
import BackButton from "../commons/BackButton";

class MembershipRequests extends Component {

    state = {
        groupId: null,
        members: [],
        showGroupPortal: false
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.setState({ groupId: this.props.match.params.id });
        this.getData();
    }

    getData() {
        getJoinRequestsForGroup(this.props.token, this.props.match.params.id).then(res => {
            if (res.data.OK) {
                this.setState({ members: res.data.pending })

            }
        })
    }


    render() {
        const { t } = this.props;
        return (
            <>
                <div className="main-content">
                    <div className="main-content-narrow">
                {this.state.members.length < 0  ? <BackButton/>:''}

                        <div className={"user-card "}>
                            <div className="user-card-content">{t('group_portal_nav_req')}</div>
                        </div>
                        {this.state.groupId &&
                            <div className="newPostBtnHolder">
                                <IconButton
                                    icon="groups"
                                    text={t('group_portal_nav_title_primary') + ' ' + t('group_portal_nav_title_secondary')}
                                    type="button"
                                    className={""}
                                    handleClick={() => this.setState({ showGroupPortal: true })}
                                />
                            </div>
                        }
                        {this.state.members.length > 0 ? (
                            this.state.members.map((item, i) => (
                                <UserCard
                                    key={i}
                                    item={item}
                                    dataChild={<UserData item={item} showOnlyTimestamp={true} timestampFieldName={"requested"} />}
                                >
                                    {this.props.profile.userId != item.FK_userID &&
                                        <div className={"memberActionBtnsHolder"}>
                                            <Button
                                                clear={true}
                                                text={t('member_request_screen_confirm_button_text')}
                                                handleClick={() => confirmPendingUser(this.props.token, this.state.groupId, item.FK_userID)
                                                    .then(res => this.getData())
                                                }
                                            />
                                            <Button
                                                clear={true}
                                                text={t('member_request_screen_decline_button_text')}
                                                handleClick={() => declinePendingUser(this.props.token, this.state.groupId, item.FK_userID)
                                                    .then(res => this.getData())}
                                            />
                                        </div>}
                                </UserCard>
                            ))
                        ) : (
                                <></>
                            )}
                    </div>
                </div>
                {this.state.showGroupPortal &&
                    <GroupPortal showGroupPortal={this.state.showGroupPortal} handleClose={() => this.setState({ showGroupPortal: false })} groupId={this.state.groupId} />
                }
            </>
        );
    }
}

export default withTranslation()(withRouter(MembershipRequests));
