export const footer_menu = [
    {
        name: "terms_and_conditions",
        link: "/terms-and-conditions"
    },
    {
        name: "privacy_policy",
        link: "/privacy-policy"
    },
    {
        name: "imprint_title_primary",
        link: "/imprint"
    },
    // {
    //     name: "FAQ_support",
    //     link: "/FAQAndSupport"
    // },
  
]