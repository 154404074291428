import React, { Component } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { getNotifications } from "../../api/user";
import Button from "../elements/buttons/Button";
import "react-datepicker/dist/react-datepicker.css";
import "rc-slider/assets/index.css";
import "./Notifications.scss";
import UserCard from "../elements/cards/UserCard";
import IconLink from "../elements/buttons/IconLink";
import NotificationData from "../elements/cards/NotificationData";
import { returnUrl } from "./mapping";
import BackButton from "../commons/BackButton";
import { withTranslation } from "react-i18next";
import { joinGroup } from "../../api/groups";
import { withRouter } from "../customNavigate/withRouter";
import EditPost from "../posts/edit-post/index";
import { deleteAllNotification, markReadNotification } from "../../api/common";
import IconButton from "../elements/buttons/IconButton";
import { connect } from "react-redux";
import { mapStateToProps } from "lib/store/mapStateToProps";
import * as GlobalStore from 'lib/store/global';
import DeleteModal from "../commons/DeleteModal";
import { TRUE } from "sass";
import { NavLink } from "react-router-dom";



class Notifications extends Component {
  loadingRef = React.createRef()
  constructor(props) {
    super(props);
  }
  state = {
    list: [],
    showModal: false,
    inviterName: "",
    groupName: "",
    groupData: {},
    isGroupJoined: false,
    responseText: "",
    showPendingModal: false,
    editing: false,
    editedPost: {},
    isEditable: false,
    groupId: null,
    offset: 0,
    prevY: 0,
    totalNoti: 0

  };

  componentDidMount() {
    this.props.updateNotificationCount("")

    this.getData();
    window.addEventListener("scroll", this.handleScroll); 
    if ((this.props.history.location.state !== undefined ||null )&& this.props.history.location.state?.noti && (this.props.history.location.state?.itm!==undefined || null) ) {
        let itemm = this.props.history.location.state?.itm
        this.setState({
          inviterName: itemm?.senderName,
          groupName: itemm?.groupName,
          showModal: true,
          groupData: itemm,
        });
      }
    

    return () => {
      window.removeEventListener("scroll", this.handleScroll);
    };

  }

  _this = this;
  handleScroll = (e) => {
    let scrollTop = window.scrollY;
    let docHeight = document.body.offsetHeight;
    let winHeight = window.innerHeight;
    let scrollPercent = scrollTop / (docHeight - winHeight);
    let scrollPercentRounded = Math.round(scrollPercent * 100);
    if (scrollPercentRounded > 85 && this.state.totalNoti > 0) {
      if (this.loadingRef.current) {
        return;
      }

      this.setState({
        offset: this.state.offset + 10,
      })
      this.getData();
    }


  };
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);

  }

  getData() {
    this.loadingRef.current = true
    getNotifications(this.props.token, this.state.offset).then((res) => {
      if (res.data.OK) {

        this.setState({
          totalNoti: res.data?.not?.length,
          list: [...this.state.list, ...res.data.not]
        });

      }
      this.loadingRef.current = false

    });
  }

  markMailAsRead = async (id) => {
    try {
      const response = await markReadNotification(this.props.token, id)
      if (response.data.OK)
        return true
    }
    catch (e) {
      console.log(e)
    }

  }

  async onCardClick(item) {
    let id = item.notid
    const respose = await this.markMailAsRead(id)
    if (respose) {
      this.props.updateNotificationCount("")

      const params = new URLSearchParams(item.query);
    if(item.type==="Plike" && item.groupId!=="0" || item.type==="Pfollow" && item.groupId!=="0")
      return this.props.history.push(`/post/${item.postId}?g=${item.groupId}`);
     
     else {
      switch (item.type) {

        case "Pgrpinvite":
          return this.setState({
            inviterName: item.name,
            groupName: item.title,
            showModal: true,
            groupData: item,
          });
        case "Pgrpjoinpending":
          return this.props.history.push(`/group/${item.postID}/requests`);
        case "Pgrpjoin":
          return this.props.history.push(`/group/${item.groupId}/members`);
        case "Pgrpadminpostnotify":
          return this.props.history.push(`/group/${item.groupId}`);
        case "Pgrpjoinaccept":
          return this.props.history.push(`/group/${item.postID}`);
        case "Pfollow":
          return this.props.history.push(`/post/${item.postID}`);

        case "Pmail":
          return this.props.history.push(`/chat/${params.get("userId")}`);
        case "Pcom":
          return this.props.history.push(`/post/${item.postID}`);
        case "Plike":
          return this.props.history.push(`/post/${item.postID}`);
        case "Plikegrp":
          return this.props.history.push(`/post/${item.postID}?g=${item.groupId}`);
        case "Pcomgrp":
          return this.props.history.push(`/post/${item.postID}?g=${item.groupId}`);
        case "Pintern":
          return this.props.history.push(`/post/${item.postID}?g=${item.groupId}`);
        case "Pfavorite":
          return this.props.history.push(`/post/${item.postID}`);
        case "Preg":
          return this.props.history.push(`/post/${item.postID}`);


        default:
          break;
      }
    }
    }
  }

  deleteNotifications = async () => {
    try {
      const response = await deleteAllNotification(this.props.token)
      if (response.data.OK)
        this.props.updateNotificationCount("")
      this.setState({
        list: [],
        showDeleteModal: false
      })
      this.getData();

    }
    catch (e) {
      console.log(e)
    }
  }



  onAcceptClick() {

    if (this.state.isGroupJoined) {
      const group = this.state.groupData;

      this.setState({
        showModal: false,
        isGroupJoined: false,
      });
      // this.setState({
      //   editing: true,
      // });
      this.props.history.push("/group/" + group.groupId);
    } else {
      const group = this.state.groupData;
      joinGroup(this.props.token, group.groupId, "", group.postID).then(
        (res) => {
          // console.log(res.data.OK);
          if (res.data.OK === "joined") {
            this.setState({ isGroupJoined: true });
            this.props.history.push("/group/" + group.groupId);
          } else if (res.data.OK === "joined pending") {
            // console.log(res.data.OK);
            this.setState({
              showModal: false,
              responseText: res.data.OK.split(" ").join("_").substring(0),
              showPendingModal: true,
            });
          } else {
            this.setState({
              showModal: false,
              responseText: res.data.ER,
              showPendingModal: true,
            });
          }
        }
      );
    }
  }

  onDeleteClick() {

    this.setState({
      showDeleteModal: true
    })


  }


  render() {
    const { t } = this.props;



    return (
      <div className="notifications main-content">
        <div className="main-content-narrow">
          <div className="cta d-flex justify-content-between">
            {/* <BackButton /> */}
            <div></div>

            {this.state.list?.length > 0 && <div className="deleteAll">
              <IconButton
                icon="delete"
                outlined={true}
                text={t("delete")}
                clear={true}
                handleClick={() => this.onDeleteClick()}
              />
            </div>}

          </div>

          <div
            className={
              "dot-flashing " + (this.state.loading ? " " : "d-none")
            }
            ref={this.loadingRef}
          ></div>

          {console.log(this.state.list)}

          {this.state.list && this.state.list?.length > 0 ? this.state.list.map((item, i) => (
            <UserCard
              key={i}
              item={item}
              dataChild={<NotificationData item={item} />}
              className={
                item?.type === "Pgrpinvite" || "Pcomgrp" ? "cursor" : ""
              }
              handleClick={() => this.onCardClick(item)}
            >
              <IconLink
                outlined={item?.read === "y" ? true : false}
                className="icon-mr-0"
                icon={item?.read === "y" ? "notifications_none" : "notifications_active"}
                link={returnUrl(item)}
              />
            </UserCard>
          )) :
            <div style={{ textAlign: 'center' }}>{t("no-notification-msg")}</div>}
        </div>
        <Modal
          show={this.state.showModal}
          onHide={() =>
            this.setState({ showModal: false, isGroupJoined: false })
          }
          contentClassName="invite-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.isGroupJoined
                ? t("invitation_accepted")
                : t("grp_invitation")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {this.state.isGroupJoined
                ? t("invite_success")
                : `${this.state.inviterName} ${t("invite_details")}
              ${this.state.groupName}`}
            </div>
            <div className="cmargin">
              {this.state.isGroupJoined
                ? t("new_post_heading")
                : t("invite_confirmation")}{" "}
            </div>
        
          </Modal.Body>

          <Modal.Footer>
           {!this.state.isGroupJoined &&  <Button
              text={t("enter_group_screen_group_info")}
              handleClick={() => this.props.history.push(`/group/${this.state.groupData?.groupId}/about?gid=${this.state.groupData?.postID}`)}
            />}
             <Button
              text={t("enter_group_screen_enter_group_prompt_close_action")}
              handleClick={() => this.setState({ showModal: false })}
            />

            <Button
              text={
                this.state.isGroupJoined
                  ? t("add_edit_post_create")
                  : t("accept")
              }
              handleClick={() => this.onAcceptClick()}
            />
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showPendingModal}
          onHide={() => this.setState({ showPendingModal: false })}
          contentClassName="invite-modal"
        >
          <ModalBody>{t(this.state.responseText)}</ModalBody>
          <Modal.Footer>
            <Button
              text={t("close")}
              handleClick={() => this.setState({ showPendingModal: false })}
            />
          </Modal.Footer>
        </Modal>

        {/* {this.state.editing && <EditPost editing={this.state.editing}
        token={this.props.token}
        isEditable={this.state.isEditable}
        editing={this.state.editing}
        editedPost={this.state.editedPost}
        groupId={this.state.groupData.groupId}
        stopEdit={() =>
          this.setState({ isEditable: false, editing: false })
        } />} */}

        {this.state.showDeleteModal && <DeleteModal show={this.state.showDeleteModal} title={"delete_notification_heading"} content={"delete_notification_content"} closeModal={() => this.setState({
          showDeleteModal: false
        })} onSubmit={() => this.deleteNotifications()} />}
      </div>
    );
  }
}

const mapDispatchToProps = {
  updateNotificationCount: GlobalStore.updateNotificationCount
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(Notifications)));

// export default withTranslation()(withRouter(Notifications));
