import React, { Component } from "react";
import "rc-slider/assets/index.css";
import "./Inbox.scss";
import moment from "moment";

import IconLink from "../elements/buttons/IconLink";
import { getInbox } from "../../api/chat";
import UserCard from "../elements/cards/UserCard";
import LastMessage from "../elements/cards/LastMessage";
import { withTranslation } from "react-i18next";
import BackButton from "../commons/BackButton";
import SocketContext, {  useSocketContext } from "lib/context/socketContext";
import { SOCKET_URL } from "../../global";
import Loader from "../../components/posts/single-post/PostLoader/postLoader.js";
import { connect } from "react-redux";
import { mapStateToProps } from "lib/store/mapStateToProps";
import * as GlobalStore from 'lib/store/global';
import { dateFormatter } from "lib/utils/Commons";
import UserDetails from "../user-details/index";
import DeleteModal from "../commons/DeleteModal";
import ChatFileModal from "../commons/chatFileModal";
import { withRouter } from "../customNavigate/withRouter";

// import {EVENT_ENUM} from "../../utils/ChatEvents";

class Inbox extends Component { 
  //  static contextType = SocketContext;   
  constructor(props){
    super(props);
  }
  socket = {};
  state = {
    newChat: false,
    currentUserId: "",
    intervalId: null,
    loading: true,
    query: "",
    emptyChat: "",
    showChatModal: false,
  };   

  // dispatch = useDispatch();

  getSockets() { 
    const _this = this; 
       
      console.log(_this.socket.server,"sdsd" );
    if (
      _this.socket &&
      _this.socket.server !== null && 
      _this.socket.server.readyState !== 3
    ) {
      console.log(
        "reusing the socket connection [state = " +
          _this.socket.server.readyState +
          "]: " +
          _this.socket.server.url
      );    

      if (_this.socket.server.readyState === WebSocket.OPEN) {
        console.log('WebSocket connection is open');
      } else {
        console.log('WebSocket connection is not open');
      }
        
    
      
      return new Promise(function (resolve, reject) {  

        _this.socket.server.onerror = function (err) {
          console.error("socket connection error : ", err);
          _this.setState({ loading: false });
          reject(err);
        };
    
          if(_this.socket.server.readyState === WebSocket.OPEN){ 
                  console.log("socket hhh");
            _this.socket.server.onmessage=(e)=>{
              _this.onMessageListener(e); 
            }   

               resolve(_this.socket.server);
       
           }
           else{
            _this.socket.server.onopen = function () {
              console.log(
                "socket connection is opened [state = " +
                  _this.socket.server.readyState +
                  "]: " +
                  _this.socket.server.url
              ); 
              _this.socket.server.onmessage=(e)=>{
                _this.onMessageListener(e);
              } 
              resolve(_this.socket.server);
              // _this.setState({ loading: false });
            }; 
           }
  
       
      });

      
    }
    return new Promise(function (resolve, reject) { 
    
      _this.socket.server = new WebSocket(SOCKET_URL + _this.props.token);  
      _this.props.socketContext.setWebSocketConnection(_this.socket.server)
        if(_this.socket.server.readyState !== WebSocket.OPEN){
      _this.socket.server.onopen = function () {
        console.log(
          "socket connection is opened [state = " +
            _this.socket.server.readyState +
            "]: " +
            _this.socket.server.url
        ); 
        _this.socket.server.onmessage=(e)=>{
          _this.onMessageListener(e);
        } 
        resolve(_this.socket.server);
        // _this.setState({ loading: false });
      }; 
    }

      _this.socket.server.onerror = function (err) {
        console.error("socket connection error : ", err);
        _this.setState({ loading: false });
        reject(err);
      };
    });
  }

  componentDidMount() {
    console.log('sdsd',this.props.socketContext.webScoketRef) 
     this.socket.server = this.props.socketContext.webScoketRef.current
    let userData = JSON.parse(localStorage.getItem("profile"));
   
    this.setState({ currentUserId: userData.userId });
    this.getInboxList(userData.userId); 

    // this.getSockets();
    // console.log("interval", this.state.intervalId);
    // if (!this.state.intervalId) {
    //   console.log("clear interval")
    //   clearInterval(this.state.intervalId);
    //   this.setState({ 
    //     intervalId: setInterval(() => {
    //       console.log("interval")
    //       this.getSockets();
         
    //     }, 10000),
    //   });
    // }
  }

  getInboxList = (userid) => {
    let _this = this; 
    this.getSockets()
      .then(function (server) {
        console.log("then getSockets", server);  
      // this.props.socketContext.setWebSocketConnection(server)
        console.log( server.readyState,"kkk");
        if (server.readyState === 1) {
          let param = {
            event: "get_user_chat_list",
            current_user: userid,
            search: _this.state.query,
          };
          server.send(JSON.stringify(param));  
       }
          }
      )
      .catch(function (err) {
        // error here
        console.log("error on getSocktes", err);
      });
  };

  onMessageListener(event) {
    console.log("listner", [event]);
    const data = JSON.parse(event.data);
    if (data.event == "get_user_chat_list") {

      let rr = this.sortList(data.list);

      console.log(rr)


      this.setState({ inbox: rr });
      // this.setState({inbox: data.list})

      this.setState({ loading: false });

      if (data.list?.length < 1)
        this.setState({ emptyChat: "empty_chat_list_old" });
    } else if (data.event == "send_chat" || data.event == "upload_attachment") {
      this.getInboxList(this.state.currentUserId);
    }
  }

  componentDidUpdate() {
    if (this.state.query != this.props.query) {
      this.setState({ query: this.props.query });
      console.log("check3");
      this.getInboxList();
    }
  }

  componentWillUnmount() {
    if (this.socket.server && this.socket.server.readyState < 2) {
      console.log("closing socket");
      // this.socket.server.close();
    }
    clearInterval(this.state.intervalId);
    this.setState({ intervalId: null });
  }

  clickOnCard(id) {   
    this.props.search("")
    this.props.history.push("/chat/" + id);
  }

  onCardClick(e, clickedOn, data) {
    e.stopPropagation();
    console.log(data);
    if (clickedOn === "img") {
      if (data.CAR_type === "p") {
        this.setState({
          userId: data.userID,
          showUserDetails: true,
        });
      } else { 
        this.props.search("")
        this.props.history.push({
          pathname: "/group-chat/" + data.chat_roomID,
          // state: { userId: data.userID }
        });
      }
    } else if (data?.CAR_type === "g") { 
      this.props.search("")
      this.props.history.push({
        pathname: "/group-chat/" + data.chat_roomID,
        // state: { userId: data.userID }
      });
    } else { 
      this.props.search("")
      this.props.history.push("/chat/" + data.userID);
    }
  }

  onMsgClick(e, data) {
    console.log({ data });
    e.stopPropagation();
    if (data?.CAR_type === "g") { 
      this.props.search("")
      this.props.history.push({
        pathname: "/group-chat/" + data.chat_roomID,
      });
    } else{  
      this.props.search("")
      this.props.history.push("/chat/" + data.userID);
  }
  }

  sortList(data) {
    let unreadmessage = data
      ?.filter((item) => item.LCM_read === "n")
      .sort(
        (a, b) =>
          new Date(b?.LC_created_on).getTime() -
          new Date(a?.LC_created_on).getTime()
      );
    let readmessages = data
      ?.filter((item) => item.LCM_read === "y")
      .sort(
        (a, b) =>
          new Date(b?.LC_created_on).getTime() -
          new Date(a?.LC_created_on).getTime()
      );

    return [...unreadmessage, ...readmessages];


  }

  render() {
    const { t } = this.props;
    console.log(this.context,"rest");
    
    return (
      <>
        {this.state.loading ? (
          <Loader />
        ) : (
          <div className="inbox main-content">
            <div className="main-content-narrow">
              {/* {!this.state.showAsModal && (
                <div className="backCta">
                  <BackButton />
                </div>
              )} */}

              {/* {console.log("a",this.state.inbox)} */}

              {this.state.inbox && this.state.inbox?.length > 0 ? (
                this.state.inbox.map((x, i) => (
                  <div key={i} className="decoration-none">
                    <UserCard
                      item={x}
                      img={x.CAR_type === "p" ? x.USE_pic : x.CAR_avatar}
                      className={
                        x.LCM_fromId == this.state.currentUserId
                          ? "read cursor"
                          : x.LCM_read === "y"
                          ? "read cursor"
                          : "unread cursor"
                      }
                      dataChild={<LastMessage item={x} />}
                      handleClick={(e, clickedOn) =>
                        this.onCardClick(e, clickedOn, x)
                      }
                    >
                      <span>
                        {moment(x?.LC_created_on, "yyyy-MM-DD hh:mm:ss").format(
                          "DD/MM/YYYY HH:mm"
                        )}
                      </span>
                      {x.LCM_fromId == this.state.currentUserId ? (
                        <i
                          className="material-icons material-icons-outlined"
                          onClick={(e) => this.onMsgClick(e, x)}
                        >
                          {"mark_chat_read"} 
                        </i>
                      ) : (
                        <i
                          className={
                            x.LCM_read === "y"
                              ? "material-icons material-icons-outlined"
                              : "material-icons material-icons-filled"
                          }
                          onClick={(e) => this.onMsgClick(e, x)}
                        >
                          {x.LCM_read === "y"
                            ? "mark_chat_read"
                            : "mark_chat_unread"}
                        </i>
                      )}
                      <span className="chatTag">
                        {x.CAR_type === "p"
                          ? t("private-chat")
                          : (x?.Admin === "y" ? t("administrator") : t("my_groups_member")) }
                      </span>
                    </UserCard>
                  </div>
                ))
              ) : (
                <div className="noDataTitle">{t(this.state.emptyChat)}</div>
              )}
            </div>

            {
              <div className="newPostBtnHolder">
                <IconLink
                  icon=""
                  text={t("plus_message_button_title")}
                  className="link-btn"
                  handleClick={() => this.setState({ showChatModal: true })}
                />
              </div>
            }
          </div>
        )}

        {this.state.showUserDetails && (
          <UserDetails
            id={this.state.userId}
            {...this.props}
            handleClose={() =>
              this.setState({
                showUserDetails: false,
              })
            }
          />
        )}

        {this.state.showChatModal && (
          <ChatFileModal
            show={this.state.showChatModal}
            textBtnOne={t("private-chat")}
            textBtnTwo={t("group-chat")}
            lowerCase={true}
            btnOne={() => this.props.history.push("/new-chat")}
            btnTwo={() => this.props.history.push("/create-chat-group")}
            closeModal={() =>
              this.setState({
                showChatModal: false,
              })
            }
          />
        )}
      </>
    );
  }
}

const mapDispatchToProps = {
  search: GlobalStore.search,
};

const withSocketContext = (Component) => {
  const Wrapper = (props) => {
    const context = useSocketContext();

    return <Component socketContext={context} {...props} />;
  };

  return Wrapper;
};
 
export default withTranslation()(connect(mapStateToProps,mapDispatchToProps)(withRouter(withSocketContext(Inbox))));
