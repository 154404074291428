import React, { Component, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { getMunicipality, getProfileData, saveProfile } from "../../api/user";
import Button from "../elements/buttons/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Profile.scss";
import moment from "moment";
import Axios from "axios";
import FileList from "../upload";
import DropdownSearch from "../elements/dropdown-search";
import ChangePassword from "./ChangePassword";
import { changeLaguage } from "../../i18n";
import { withTranslation } from "react-i18next";
import HtmlParser from "html-react-parser";
import BackButton from "../commons/BackButton";
import linkifyHtml from "linkify-html";
import ReactHtmlParser from "html-react-parser";
import IconButton from "../elements/buttons/IconButton";
import { dateFormatter } from "lib/utils/Commons";
import { deleteUserAccount } from "../../api/common";
import { useAuthDispatch } from "lib/context/authContext";
import { logoutUser } from "lib/context/authActions";
import { mapStateToProps } from "lib/store/mapStateToProps";
import { connect } from "react-redux";
import { withRouter } from "../customNavigate/withRouter";


class MyProfile extends Component {
  constructor(props) {
    super(props);
    this.postcodeOffset = React.createRef();
    this.totalPostcode = React.createRef()
  }
  state = {
    editing: false,
    avatar: [],
    profile: {},
    languages: {},
    countries: {},
    message: "",
    response: "",
    regTown: null,
    noDataFoundMsg: "",
    showDeleteModal: false,
    isUpadte: false,
    municipalities: []
  };

  componentDidMount() {
    console.log('context', this.context);
    if (this.props.token) {
      this.getData();
    }
  }

  setAvatar = (avatar) => {
    console.log(avatar, "sssL");
    this.setState({ avatar: avatar });
  };

  getData() {
    getProfileData(this.props.token).then(
      Axios.spread((...responses) => {
        if (responses[0].data.OK) {
          const data = responses[0].data.OK;
          // console.log("data", data); 
          if (this.state.isUpadte) {
            localStorage.setItem('profile', JSON.stringify(data));
            this.props.authContext({ type: "UPDATE_PROFILE", profile: data })
            this.setState({ isUpadte: false })
          }
          this.setState({
            profile: data,
            gender: data.USE_sex,
            firstname: data.USE_firstname,
            lastname: data.USE_surename,
            email: data.USE_email,
            postcode: data.USE_plz,
            birthday: data.USE_birth,
            about: data.LCU_mess,
            phone: data.USE_mobile,
            myState: data.FK_adr_stateID,
            myLang: data.FK_languageID,
            regTown: data.FK_adr_cityID,
            avatar: [
              {
                preview: data.USE_pic
                  ? data.USE_pic
                  : data.LCU_fbpic
                    ? data.LCU_fbpic
                    : "/images/profile.png",
              },
            ],
            mySelf: data.myself === "y",
          });

          getMunicipality(data.USE_plz, data.FK_adr_stateID).then((res) => {
            if (res.data.OK) {
              let data = res.data.plz;

              let postcodeCity = data.find(
                (me) => me.id === this.state.regTown
              );
              console.log({ postcodeCity });
              this.setState({ postcodeCity: postcodeCity?.n });
            }
          });

          if (!data.USE_plz || !data.FK_languageID || !data.FK_adr_stateID) {
            this.setState({
              message:
                "Please set your Postcode, language and country before continuing",
            });
          }
        }
        if (responses[1].data.OK) {
          const data2 = responses[1].data.OK;
          this.setState({
            languages: data2.lang,
            countries: data2.state,
          });
        }
      })
    );
  }

  showErrorModal = () => {
    this.setState({
      errorClass: "text-denger",
      response: "invalid_postcode",
      showModal: true,
    });
  };

  submitProfileForm(e) {
    e.preventDefault();
    localStorage.removeItem('profile')
    if (this.state.regTown === null) {
      this.showErrorModal();
    } else {

      saveProfile(this.props.token, this.state).then((res) => {
        if (res.data.OK) {
          // window.location.reload();
          if (res.data.OK === "upload")
            this.setState({
              errorClass: undefined,
              showModal: true,
              response: "profile_saved_msg",
              editing: false,
              isUpadte: true
            });
          this.getData();
          changeLaguage(this.state.myLang);
        }
        else {
          this.setState({
            errorClass: "error",
            showModal: true,
            response: res.data.ER,

          });
        }
      });
    }
  }

  closeModal() {
    this.setState({
      showModal: !this.state.showModal,
    });
  }

  getLocationFromPost(code, state) {
    const { t } = this.props;
    if (this.postcodeOffset.current !== this.totalPostcode.current) {

      getMunicipality(code, state,this.postcodeOffset.current).then((res) => {
        if (res.data.OK && res.data.plz.length > 0) {
          this.setState({ municipalities: [...this.state.municipalities, ...res.data.plz], noDataFoundMsg: "" });
          this.postcodeOffset.current = res.data.offset
          this.totalPostcode.current = res.data.total
        } else {
          this.setState({
            municipalities: [],
            regTown: null,
            noDataFoundMsg: t("no_result_found"),
          });
        }
      });
    }
  }

  onCountryChange(e) {
    this.setState({ myState: e.target.value });
    if (e.target.value !== this.state.profile.FK_adr_stateID) {
      this.setState({ postcodeCity: "", municipalities: [], regTown: null });
      this.postcodeOffset.current = 0

    }
  }

  async deleteThisAccount() {
    const { t } = this.props;


    const response = await deleteUserAccount(this.props.token);
    if (response.data.OK) {
      alert(t(`${response.data.OK}`))
      this.callLogOut()

    }
  }

  callLogOut = async () => {
    // this.props.history.push("/login");
    try {
      await logoutUser(this.props.authContext)
        .then((res) => {
          this.props.history.push("/login");
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const { t } = this.props;
    const editing = this.state.editing;
    const data = this.state.profile;
    const birthDate =
      this.state.birthday !== "0000-00-00"
        ? moment(this.state.birthday, "YYYY-MM-DD").toDate()
        : moment.now();

    const states = this.state.countries;

    console.log(this.state.isUpadte, "ser");
    const languages = this.state.languages;

    const myLang = this.state.myLang;
    const myState = this.state.myState;

    const avatarSrc = data.USE_pic
      ? data.USE_pic
      : data.LCU_fbpic
        ? data.LCU_fbpic
        : "/images/profile.png";

    return (
      <div className="my-profile main-content">
        {console.log("feed", this?.props?.selectedLang)}
        <div className="main-content-narrow">
          {this.state.message && (
            <span className="formMessage">{this.state.message}</span>
          )}
          <div className="my-profile-box">
            <div className="my-profile-header">
              <h2 className="m-0 p-0">{t("edit_profile_title_primary")}</h2>
              {!editing && (
                <Button
                  text={t("edit_profile")}
                  clear={true}
                  handleClick={() => this.setState({ editing: true })}
                />
              )}
            </div>
            <div className="my-profile-content">
              <Form
                className="profileForm"
                onSubmit={(e) => this.submitProfileForm(e)}
              >
                <div className="name-group">
                  {editing ? (
                    <div className="avatar-box">
                      <FileList
                        id={"mediaUpload"}
                        fileTypes={".png, .jpg, .jpeg"}
                        setFiles={this.setAvatar}
                        files={this.state.avatar}
                        icon="add_a_photo"
                        single={true}
                        previews={true}
                      />
                    </div>
                  ) : (
                    <img src={avatarSrc} />
                  )}
                  {editing ? (
                    <div className="name-form-group">
                      <Form.Group size="lg" className="form-group" controlId="firstname">
                        <Form.Control
                          required
                          type="text"
                          value={this.state.firstname}
                          onChange={(e) =>
                            this.setState({ firstname: e.target.value })
                          }
                        />
                      </Form.Group>

                      <Form.Group size="lg" className="form-group" controlId="lastname">
                        <Form.Control
                          required
                          type="text"
                          value={this.state.lastname}
                          onChange={(e) =>
                            this.setState({ lastname: e.target.value })
                          }
                        />
                      </Form.Group>
                    </div>
                  ) : (
                    <span className="name">
                      {data.USE_firstname} {data.USE_surename}
                    </span>
                  )}
                </div>

                <div className="row mt-30">
                  {/* gender */}
                  {/* <div className="col-lg-6">
                    <Form.Group size="lg" controlId="gender">
                      <Form.Label>{t("view_profile_sex")}</Form.Label>
                      {editing ? (
                        <>
                          <div key={`default-radio`} className="mb-3">
                            <Form.Check
                              type={"radio"}
                              id={"radio-male"}
                              name="gender-radios"
                              label={t("male")}
                              checked={this.state.gender === "M"}
                              onChange={() => this.setState({ gender: "M" })}
                            />

                            <Form.Check
                              type={"radio"}
                              id={"radio-female"}
                              name="gender-radios"
                              label={t("female")}
                              checked={this.state.gender === "F"}
                              onChange={() => this.setState({ gender: "F" })}
                            />
                          </div>
                        </>
                      ) : (
                        <span>
                          {data.USE_sex === "F" ? t("female") : t("male")}
                        </span>
                      )}
                    </Form.Group>
                  </div> */}

                  {/* email */}
                  <div className="col-lg-6">
                    <Form.Group size="lg" className="form-group" controlId="email">
                      <Form.Label>{t("email")}</Form.Label>
                      {editing ? (
                        <Form.Control
                          required
                          type="email"
                          value={this.state.email}
                          rows={2}
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                        />
                      ) : (
                        <span>{data.USE_email}</span>
                      )}
                    </Form.Group>
                  </div>

                  {/* country */}
                  <div className="col-lg-6">
                    <Form.Group size="lg" className="form-group" controlId="country">
                      <Form.Label>{t("country")}</Form.Label>
                      {editing ? (
                        <Form.Control
                          as="select"
                          required
                          onChange={(e) => this.onCountryChange(e)}
                        >
                          {states &&
                            Object.keys(states).map(function (
                              keyName,
                              keyIndex
                            ) {
                              return (
                                <option
                                  key={keyIndex}
                                  selected={myState === keyName}
                                  value={keyName}
                                >
                                  {states[keyName]}
                                </option>
                              );
                            })}
                        </Form.Control>
                      ) : (
                        <span>{states[myState]}</span>
                      )}
                    </Form.Group>
                  </div>

                  {/* Postcode, City */}
                  <div className="col-lg-6">
                    <Form.Group
                      className="position-relative"
                      size="lg"
                      className="form-group"
                      controlId="postcodecity"
                    >
                      <Form.Label>{t("postcode")}</Form.Label>
                      {editing ? (
                        <>
                          <Form.Control
                            required
                            type="text"
                            autoComplete="off"
                            value={this.state.postcodeCity}
                            onFocus={() =>
                              this.setState({ openDropdownSearch: true })
                            }
                            onChange={(e) => {
                              this.setState({ postcodeCity: e.target.value, municipalities: [] });
                              // if (e.target.value.length > 2) 
                              this.postcodeOffset.current = 0

                              this.getLocationFromPost(
                                e.target.value,
                                myState
                              );
                            }}
                          />
                          <div className="error-msg">
                            {this.state.noDataFoundMsg}
                          </div>
                          {this.state.municipalities &&
                            this.state.openDropdownSearch && (
                              <DropdownSearch
                                results={this.state.municipalities}
                                handleClick={(id, name) =>
                                  this.setState({
                                    regTown: id,
                                    postcodeCity: name,
                                    openDropdownSearch: false,
                                  })
                                }
                                fetchData={() => this.getLocationFromPost(this.state.postcodeCity, myState)}

                              />
                            )}
                        </>
                      ) : (
                        <span>{this.state.postcodeCity}</span>
                      )}
                    </Form.Group>
                  </div>

                  {/* language */}
                  <div className="col-lg-6">
                    <Form.Group size="lg" className="form-group" controlId="language">
                      <Form.Label>{t("language")}</Form.Label>
                      {editing ? (
                        <Form.Control
                          as="select"
                          required
                          onChange={(e) =>
                            this.setState({ myLang: e.target.value })
                          }
                        >
                          {languages &&
                            Object.keys(languages).map(function (
                              keyName,
                              keyIndex
                            ) {
                              return (
                                <option
                                  key={keyIndex}
                                  selected={myLang === keyName}
                                  value={keyName}
                                >
                                  {languages[keyName]}
                                </option>
                              );
                            })}
                        </Form.Control>
                      ) : (
                        <span>{languages[myLang]}</span>
                      )}
                    </Form.Group>
                  </div>

                  {/* birthday */}
                  {/* <div className="col-lg-6">
                    <Form.Group size="lg" controlId="birthday">
                      <Form.Label>{t("edit_profile_birthdate")}</Form.Label>
                      {editing ? (
                        <DatePicker
                          selected={birthDate}
                          dateFormat="dd/MM/yyyy"
                          onChange={(date) =>
                            this.setState({
                              birthday: moment(date)
                                .format("YYYY-MM-DD")
                                .toString(),
                            })
                          }
                        />
                      ) : (
                        <span>
                          {this.state.birthday === "0000-00-00"
                            ? "/"
                            : dateFormatter(this.state.birthday)}
                        </span>
                      )}
                    </Form.Group>
                  </div> */}

                  {/* about me */}
                  <div className="col-lg-6">
                    <Form.Group size="lg" className="form-group" controlId="phone">
                      <Form.Label>{t("edit_profile_telephone")}</Form.Label>
                      {editing ? (
                        <Form.Control
                          type="text"
                          value={this.state.phone}
                          onChange={(e) =>
                            this.setState({ phone: e.target.value })
                          }
                        />
                      ) : (
                        <span>{data.USE_mobile ? data.USE_mobile : "/"}</span>
                      )}
                    </Form.Group>

                    <Form.Group size="lg" className="form-group" controlId="aboutme">
                      <Form.Label>{t("edit_profile_aboutMe")}</Form.Label>
                      {editing ? (
                        <Form.Control
                          type="text"
                          value={this.state.about}
                          onChange={(e) =>
                            this.setState({ about: e.target.value })
                          }
                        />
                      ) : (
                        <span className="aboutme">
                          {data.LCU_mess
                            ?
                            ReactHtmlParser(
                              linkifyHtml(data.LCU_mess, {

                                nl2br: true,
                                target: {
                                  url: "_blank",
                                },
                              })
                            )
                            // data.LCU_mess
                            : "/"}
                        </span>
                      )}
                    </Form.Group>
                  </div>

                  <div className="col-lg-6">
                    {editing && <ChangePassword token={this.props.token} />}
                  </div>
                </div>

                {!editing && (
                  <div className="deleteAccount">
                    <IconButton
                      icon="delete"
                      outlined={true}
                      text={t("deleteAccount")}
                      clear={true}
                      handleClick={() =>
                        this.setState({ showDeleteModal: true })}
                    />
                  </div>
                )}
                {editing && (
                  <div className="profile-actions">
                    <Button
                      clear={true}
                      text={t("cancel_alert")}
                      handleClick={() => this.setState({ editing: false })}
                    />
                    <Button type="submit" text={t("save")} />
                  </div>
                )}
              </Form>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.showModal}
          onHide={() => this.closeModal()}
          className="custom-modal"
        >
          <IconButton
            clear="true"
            className="closeIcon"
            icon="close"
            handleClick={() => this.closeModal()}
          />

          <div
            className={this.state.errorClass !== undefined ? "text-danger" : ""}
          >
            {t(this.state.response)}
          </div>
        </Modal>
        <Modal
          centered
          show={this.state.showDeleteModal}
          onHide={() => this.setState({ showDeleteModal: false })}
        >
          <Modal.Body>
            <h2>{t("deleteAccount")}</h2>
            <p>{t("deleteAccount_message")}</p>

            <div className="report-actions">
              <Button
                clear="true"
                text={t("cancel_alert")}
                handleClick={() =>
                  this.setState({ showDeleteModal: false })
                }
              />
              <Button
                text={t("delete")}
                handleClick={() => this.deleteThisAccount()}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const MyProfileWrapper = (props) => {
  const authContext = useAuthDispatch();
  return <MyProfile {...props} authContext={authContext} />
}

export default withTranslation()(connect(mapStateToProps,null)(withRouter(MyProfileWrapper)));