import { t } from "i18next";
import { GroupSettingsData } from "lib/types/groupTypes";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { GroupJoinedStatus } from ".";
import { useEffect, useState } from "react";

type SingleGroupHeaderProps = {
  data: GroupSettingsData | null;
  groupJoinedStatus: GroupJoinedStatus;
  isMember: boolean;
  onJoinBtnClick: () => void;
};

const SingleGroupHeader = ({
  data,
  isMember,
  groupJoinedStatus,
  onJoinBtnClick,
}: SingleGroupHeaderProps) => {
  const [searchParams] = useSearchParams();
  const groupDataStats = [
    { id: 1, label: t("group_posts"), count: data?.totalGroupPost },
    { id: 2, label: t("group_members_screen_title_primary"), count: data?.totalGroupMember },
    { id: 3, label: t("shared_posts"), count: data?.totalSharedPost },
  ];

  const [btnText, setBtnText] = useState<string>("");

  useEffect(() => {
    if (groupJoinedStatus === GroupJoinedStatus.JOINED || isMember) {
      setBtnText(t("btn_title_joined"));
    } else if (groupJoinedStatus === GroupJoinedStatus.PENDING_TO_JOIN) {
      setBtnText(t("btn_title_join_pending"));
    } else {
      setBtnText(t("join_group"));
    }
  }, [groupJoinedStatus, isMember]);

  return (
    <CommunityCardWrapper>
      <Card>
        <ProfileSection>
          {data?.UCT_background && (
            <CoverBackgroundImage
              src={data.UCT_background}
              alt='group-background'
              className='group-background-image'
            />
          )}
          <ImageWrapper>
            <ProfileImage
              loading='lazy'
              src={data?.UCT_avatar ? data?.UCT_avatar : "/images/profile.png"}
              className='profile-image cursor'
              alt={data?.LGP_name}
            />
          </ImageWrapper>
          <Title title={data?.LGP_name}>{data?.LGP_name}</Title>
        </ProfileSection>
        {data?.LGP_short && <Description>{data?.LGP_short}</Description>}
        <Stats>
          {groupDataStats.map(({ id, label, count }) => (
            <StatItem key={id}>
              <span className='text-black'>{count}</span> {label}
            </StatItem>
          ))}
        </Stats>
        <ActionButton
          hidden={searchParams.get("redirectFrom") === "embedded"}
          disabled={isMember || groupJoinedStatus === GroupJoinedStatus.PENDING_TO_JOIN}
          onClick={() => onJoinBtnClick()}
        >
          {btnText}
        </ActionButton>
      </Card>
    </CommunityCardWrapper>
  );
};

const CommunityCardWrapper = styled.section`
  border-radius: 8px 8px 0 0;
  display: flex;
  max-width: 800px;
  padding-top: 0;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  &:has(.group-background-image) {
    background: linear-gradient(6deg, rgba(0, 0, 0, 0.2) 6.4%, rgba(0, 0, 0, 0) 65.76%);
    background-color: #c3c3c3;
    padding-top: 50px;
  }
`;

const Card = styled.article`
  border-radius: 8px 8px 0 0;
  background-color: #fff;
  z-index: 10;
  display: flex;
  margin-top: 70px;
  width: 100%;
  flex-direction: column;
  padding: 0 53px 34px;

  &:has(.group-background-image) {
    margin-top: 126px;
    border-radius: 0;
  }

  @media ${({ theme }) => theme.mediaQueries.phone} {
    padding: 0 15px 20px;
  }
`;

const ProfileSection = styled.header`
  align-self: start;
  z-index: 10;
  display: flex;
  margin-top: -46px;
  justify-content: space-between;
  gap: 20px;
  max-width: 100%;
  @media ${({ theme }) => theme.mediaQueries.phone} {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;

const CoverBackgroundImage = styled.img`
  object-fit: cover;
  object-position: center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 176px;
  z-index: 0;
  pointer-events: none;
`;

const ImageWrapper = styled.div`
  justify-content: center;
  align-items: center;
  border-radius: 22px;
  // background-color: #b21117;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
  display: flex;
  width: 107px;
  height: 107px;
  overflow: hidden;
  z-index: 1;
`;

const ProfileImage = styled.img`
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
`;

const Title = styled.h2`
  max-width: calc(100% - 127px);
  color: #000;
  align-self: end;
  margin-top: 76px;
  margin-bottom: 12px;
  flex-grow: 1;
  font:
    700 32px/119% Inter,
    sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media ${({ theme }) => theme.mediaQueries.phone} {
    max-width: 100%;
    margin-top: 0;
    align-self: center;
  }
`;

const Description = styled.p`
  color: #000;
  margin-left: 130px;
  margin-bottom: 12px;
  width: 550px;
  font:
    500 16px/22px Inter,
    sans-serif;

  @media ${({ theme }) => theme.mediaQueries.phone} {
    width: 100%;
    margin-left: 0;
    text-align: center;
  }
`;

const Stats = styled.div`
  justify-content: space-between;
  margin-left: 130px;
  display: flex;
  width: 418px;
  max-width: 100%;
  gap: 20px;
  font-size: 15px;
  color: #707270;
  font-weight: 600;
  @media ${({ theme }) => theme.mediaQueries.phone} {
    width: 100%;
    margin-left: 0;
  }
`;

const StatItem = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.primary};
`;

const ActionButton = styled.button`
  border-style: none;
  cursor: pointer;
  justify-content: center;
  border-radius: 8px;
  background-color: #95b83c;
  align-self: start;
  color: #fff;
  white-space: nowrap;
  margin: 22px 0 0 131px;
  padding: 11px 38px;
  font:
    500 16px/119% Inter,
    sans-serif;
  transition: background-color 0.2s ease;

  @media ${({ theme }) => theme.mediaQueries.phone} {
    margin: 20px auto 0;
  }

  &:hover {
    background-color: #81a032;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

export default SingleGroupHeader;
