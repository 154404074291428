import React from "react";
import ChatComponent from "../chat/ChatComponent";

class GroupChatNew extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t } = this.props;

    return <ChatComponent chattype={"group"} {...this.props} />;
  }
}
// Chat.contextType = MessageContextProvider;
export default GroupChatNew;

// compose(
//   withTranslation(),
//   connect(mapStateToProps)
// )(withRouter(withScrollHookHOC(Chat)));
//export default withTranslation()(withRouter(Chat));
