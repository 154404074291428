import React, { useMemo } from 'react';
import { useLocation, useNavigate, useParams, useMatch } from 'react-router-dom';

export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams(); 
    let match = useMatch("/group/:id")  
    // match = useMatch("/groups")
  
       if(match){
          match=true
       }else{
        match=false
       } 
       console.log(match,"ggg");
    const history = useMemo(() => ({
      push: (route) => { 
        console.log(route,"sed");
        navigate(route);
      },
      replace: (route) => {
        navigate(route, { replace: true });
      }, 
      go:(route) =>{  
        window.location.reload()
          //  console.log("dd",route);
          //  navigate(route)
      },
      location
    }), [navigate]);
     
    // const locations = useMemo(()=>(
    //   {path}
    // ),[navigate])  

    
    
    console.log(location.state,"xxx"); 


        
    return (
      <Component
        {...props}
        location={location}
        params={params}
        navigate={navigate}
        history={history} 
        matchPath={match}
        match={{params}}
        // locations={locations}
      />
    );
  }

  return ComponentWithRouterProp;
}
