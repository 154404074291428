export const XIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 12 12'
    className='fill-current'
    {...props}
  >
    <path
      fill='currentColor'
      d='M.922 11.453A.95.95 0 0 1 .664 11a1.1 1.1 0 0 1 0-.523.93.93 0 0 1 .25-.446l3.664-3.664L.914 2.711a.93.93 0 0 1-.25-.445 1.1 1.1 0 0 1 0-.516.95.95 0 0 1 .258-.46.93.93 0 0 1 .445-.25 1 1 0 0 1 .524 0 .96.96 0 0 1 .453.241L6 4.945 9.664 1.29a.93.93 0 0 1 .438-.25.96.96 0 0 1 .515 0 .9.9 0 0 1 .453.258.88.88 0 0 1 .266.453q.063.258 0 .516a.9.9 0 0 1-.258.445L7.43 6.367l3.648 3.664a.9.9 0 0 1 .258.446 1.1 1.1 0 0 1 0 .523.88.88 0 0 1-.266.445.95.95 0 0 1-.453.266 1.1 1.1 0 0 1-.515 0 .9.9 0 0 1-.438-.258L6 7.797 2.344 11.46q-.187.18-.453.25a1.1 1.1 0 0 1-.524 0 1 1 0 0 1-.445-.258'
    />
  </svg>
);
