export const main_menu = [
  {
    name: "LOCCI",
    icon: "home",
    path: "/",
    outline:true
  },
  // {
  //   name: "public_feed_name_primary",
  //   icon: "present_to_all",
  //   path: "/public-feed",
  // },
 
  {
    name: "groups",
    icon: "group",
    path: "/groups",
    outline:true
  },

  // {
  //     name: "join_group",
  //     icon: "group_add",
  //     path: "/join-group"
  // },
  // {
  //     name: "my_groups",
  //     icon: "groups",
  //     path: "/groups"
  // },

  // {
  //     name: "favorites",
  //     icon: "favorite",
  //     path: "/favorites",
  //     class: "border-white"
  // },
  // {
  //   name: "followers",
  //   icon: "person",
  //   path: "/followers",
  // },
  // {
  //   name: "add_edit_post_general_info",
  //   icon: "assignment",
  //   path: "/general",
  // },
  // {
  //   name: "blacklist",
  //   icon: "block",
  //   path: "/blacklist",
  // },

  {
    name: "inbox",
    icon: "question_answer",
    path: "/inbox",
    class: "border-white mobileMenu",
    outline:true
  },
  {
    name: "notifications",
    icon: "notifications",
    path: "/notifications",
    class: "mobileMenu",
    outline:true
  },
  // {
  //   name: "view",
  //   icon: "face",
  //   path: "/my-profile",
  //   class: "mobileMenu",
  // },
  // {
  //   name: "settings",
  //   icon: "settings",
  //   path: "/settings",
  //   class: "mobileMenu",
  // },
  // {
  //   name: "logout",
  //   icon: "close",
  //   class: "mobileMenu",
  // },
  // {
  //   name:"FAQ_support",
  //   icon:'contact_support',
  //   path:'/FAQAndSupport',

  // }
];
