import React, { useState } from "react";
import IconButton from "../buttons/IconButton";
import "./Lightbox.scss";
import moment from "moment";

function Lightbox({ media, onClose, index, setIndex, nameDate }) {
  const showNext = (e) => {
    e.stopPropagation();
    if (index >= media.length - 1) {
      setIndex(0);
    } else {
      setIndex(index + 1);
    }
  };

  const showPrev = (e) => {
    e.stopPropagation();

    if (index - 1 <= 0) {
      setIndex(media.length - 1);
    } else {
      setIndex(index - 1);
    }
  };

  const item = media[index];

  console.log(item);

  return (
    <>
      {
        <div id="locci-lightbox" onClick={onClose}>
          <IconButton
            icon="keyboard_arrow_left"
            clear={true}
            handleClick={showPrev}
            className={media && media.length > 1 ? "leftnav" : "invisible"}
          />

          <div className="imgview">
            {nameDate && (
              <span className="nameTime">
                <p className="name">{item?.USE_firstname + " " + item.USE_surename}</p>
                <p className="time">{moment(item.LC_created_on).format("DD MMMM YYYY, HH:mm")}</p>
              </span>
            )}
            {item.type === "p" || item.atype === "p" || item?.CTA_type==='p' ? (
              <img
                className="lightbox-item image"
                src={
                  item.img
                    ? item.img.replace("s_", "")
                    : item.url.replace("s_", "")
                }
                alt=""
              />
            ) : (
              <video
                className="lightbox-item"
                src={item.img ? item.img : item.url}
                id={"video-" + item.loci_imageID}
                loop
                controls={true}
                autoPlay={true}
              ></video>
            )}
          </div>
          <IconButton
            icon="keyboard_arrow_right"
            clear={true}
            handleClick={showNext}
            className={media && media.length > 1 ? "rightnav" : "invisible"}
          />

          <IconButton
            icon="close"
            clear={true}
            handleClick={onClose}
            className={"close-lightbox"}
          />
        </div>
      }
    </>
  );
}

export default Lightbox;
