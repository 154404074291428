import Button from "components/elements/buttons/Button";
import styled from "styled-components";
import { HandleLoadingState } from "components/shared/handle-loading-state";
import { useAuthState } from "lib/context/authContext.js";
import { GroupSettingsData } from "lib/types/groupTypes.js";
import { logError } from "lib/utils/logError";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation, withTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  downloadFromUnauthURL,
  fetchGroupRules,
  getGroupAbout,
  getGroupPermissions,
  joinGroup,
} from "../../api/groups.js";
import { withRouter } from "../customNavigate/withRouter.js";
import { InfoTab } from "./InfoTab";
import { PostsTab } from "./PostsTab";
import { SettingsTab } from "./SettingsTab";
import SingleGroupHeader from "./SingleGroupHeader";
import { Tab, Tabs, FeedContainer } from "./style";

export enum GroupJoinedStatus {
  INVITED = 0,
  NOT_JOINED = 1,
  PENDING_TO_JOIN = 2,
  JOINED = 3,
}

enum GROUP_TABS {
  POSTS,
  INFO,
  SETTINGS,
}

type SingleGroupProps = {
  token: string;
  groupId: string;
};

const SingleGroup = (props: SingleGroupProps) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const params = useParams<{ id: string }>();
  const [activeTab, setActiveTab] = useState<GROUP_TABS>(GROUP_TABS.POSTS);
  const [loadingTab, setLoadingTab] = useState(false);
  const [data, setData] = useState<GroupSettingsData | null>(null);
  const [groupId, setGroupId] = useState<string | null>(null);
  const [isMember, setIsMember] = useState<boolean>(false);
  const [, setShowAlreadyMemberModal] = useState(false);
  const [, setShowGoToGrpButton] = useState(false);
  const [groupJoinedStatus, setGroupJoinedStatus] = useState<GroupJoinedStatus>(
    GroupJoinedStatus.NOT_JOINED,
  );
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [showRulesModal, setShowRulesModal] = useState(false);
  const [rules, setRules] = useState<string | any[] | null>(null);
  const user = useAuthState();
  const navigate = useNavigate();

  const changeTab = (tab: number) => {
    setActiveTab(tab);
  };

  let joiningGroup = false;
  const joinGroupBtn = () => {
    if (joiningGroup) return;
    joiningGroup = true;
    const group = data as any;
    let isNewUser = "n";
    const newUserName = localStorage.getItem("newUserRegister");
    const userInfo = JSON.parse(localStorage.getItem("user") || "{}");
    if (newUserName && userInfo && userInfo.Name === newUserName) {
      isNewUser = "y";
      localStorage.removeItem("newUserRegister");
    }

    joinGroup(
      props.token,
      group.FK_user_categoryID,
      searchParams.get("pcode") as any,
      searchParams.get("gid") as any,
      isNewUser,
    ).then((res) => {
      if (res.data.OK === "joined") {
        setGroupJoinedStatus(GroupJoinedStatus.JOINED);
      } else if (res.data.OK === "joined pending") {
        setGroupJoinedStatus(GroupJoinedStatus.PENDING_TO_JOIN);
      }
    }).finally(() => {
      setShowRulesModal(false);
      setShowGroupModal(false);
      joiningGroup = false;
      location.reload();
    });
  };

  async function getGroupDataAPI() {
    setLoadingTab(true);
    const permission = await getGroupPermissions(props.token, groupId);
    if (permission.data.OK) {
      const data = permission.data.data;
      if (data.member === "1") {
        setIsMember(true);
        setGroupJoinedStatus(GroupJoinedStatus.JOINED);
      } else if (data.pending === "1") {
        setGroupJoinedStatus(GroupJoinedStatus.PENDING_TO_JOIN);
      }

      if (
        data.member === "1" &&
        searchParams.get("icode") !== "" &&
        searchParams.get("icode") !== undefined
      ) {
        if (searchParams.get("redirectFrom") === "invite") {
          setShowAlreadyMemberModal(true);
          setShowGoToGrpButton(true);
        }
      }
    }

    const about = await getGroupAbout(props.token, groupId);
    if (about.data.OK) {
      setData(about.data.OK);
    }
    setLoadingTab(false);
  }

  const getRulesData = async () => {
    setShowRulesModal(true);
    try {
      const response = await fetchGroupRules(null, data?.FK_user_categoryID);

      if (response.data) {
        const data = response.data;
        if (data.ER) {
          setRules("Error group does not exist.");
        } else {
          setRules(data.OK);
        }
      }
    } catch (error) {
      logError(error);
    }
  };

  const onDownloadClick = (data: any) => {
    downloadFromUnauthURL(null, data);
  };

  const getDocumentsView = (data: any) => {
    const getApp = () => onDownloadClick(data);

    return (
      <div className='media-box  d-flex ' key={data.name}>
        <div className='pr-1'>
          {" "}
          <i className='material-icons'>attach_file</i>
        </div>
        <div className='text-break'>{data.name === undefined ? data.LGM_img_name : data.name}</div>
        <div>
          {" "}
          <a onClick={getApp}>
            <i className='material-icons material-icons-outlined ml-15 greenIcon'>get_app</i>
          </a>
        </div>
      </div>
    );
  };

  useEffect(() => {
    getGroupDataAPI();
    // Fetch group data only when group id changes
  }, [groupId]);

  useEffect(() => {
    const groupId = params.id ?? params.id ?? props.groupId;
    setGroupId(groupId);
  }, [params.id, props.groupId]);

  return (
    <div className='container'>
      <SingleGroupHeader
        data={data}
        groupJoinedStatus={groupJoinedStatus}
        isMember={isMember}
        onJoinBtnClick={() => setShowGroupModal(true)}
      />

      <HandleLoadingState loading={loadingTab}>
        <div className='content'>
          <Card className="py-4">
            <Tabs>
              <Tab
                $active={activeTab === GROUP_TABS.POSTS}
                onClick={() => changeTab(GROUP_TABS.POSTS)}
              >
                {t("group.posts-tab")}
              </Tab>
              <Tab
                $active={activeTab === GROUP_TABS.INFO}
                onClick={() => changeTab(GROUP_TABS.INFO)}
              >
                {t("group.info-tab")}
              </Tab>
              <Tab
                $active={activeTab === GROUP_TABS.SETTINGS}
                onClick={() => changeTab(GROUP_TABS.SETTINGS)}
              >
                {t("group.settings-tab")}
              </Tab>
            </Tabs>
          </Card>
          <FeedContainer>
            {activeTab === GROUP_TABS.POSTS && (
              <PostsTab token={props.token} groupId={groupId} groupData={data} />
            )}
            {activeTab === GROUP_TABS.INFO && <InfoTab data={data} />}
            {activeTab === GROUP_TABS.SETTINGS && (
              <SettingsTab
                token={props.token}
                groupId={(groupId || "")?.toString()}
                data={data as GroupSettingsData}
              />
            )}
          </FeedContainer>
        </div>
      </HandleLoadingState>

      <Modal
        centered
        show={showGroupModal}
        onHide={() => setShowGroupModal(false)}
        backdrop='static'
        contentClassName='box'
      >
        <Modal.Body>
          {!user ? (
            <>
              <div>{t("logout_grp_join_mess")}</div>
              <div className='float-right pt-3'>
                <Button text={t("ok")} handleClick={() => navigate("/login")} />
              </div>
            </>
          ) : (
            <>
              <h2>{t("enter_group_modal_option")}</h2>
              <p>
                {t("confirm_title")}
                <b className='pl-1'>{data?.LGP_name} ?</b>
              </p>

              <div>
                {t("confirm_subtitle")}
                <b>
                  <a onClick={() => getRulesData()} className='pl-1'>
                    {t("group_rules")}{" "}
                  </a>
                </b>
                <span>({t("open_with_click")})</span>
              </div>

              <div className='report-actions pt-3'>
                <Button
                  clear='true'
                  text={t("cancel_alert")}
                  handleClick={() => setShowGroupModal(false)}
                />
                <Button text={t("enter")} handleClick={joinGroupBtn} />
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        centered
        show={showRulesModal}
        onHide={() => setShowRulesModal(false)}
        backdrop='static'
      >
        <Modal.Body>
          <h2>{t("add_edit_group_rules_title")}</h2>

          {rules === "Error group does not exist." ? (
            <p>{t("no_rules_added")}</p>
          ) : (
            rules !== null &&
            rules.length > 0 && (
              <div className='docs-wrapper'>
                {(rules as any[]).map((item) => getDocumentsView(item))}
              </div>
            )
          )}

          <div className='float-right'>
            <Button clear='true' text={t("close")} handleClick={() => setShowRulesModal(false)} />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export const Card = styled.article`
  background-color: #fff;
  padding: 28px 20px 1rem;
  margin-bottom: 20px;
  border-radius: 0 0 8px 8px;
  border-top: 1px solid #eeeeee;
`;

export default withTranslation()(withRouter(SingleGroup));
