import { useEffect, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ngxBase64 } from "lib/utils/Commons";
import IconButton from "../../elements/buttons/IconButton";
import FileList from "../../upload";
import PhotoClicker from "./PhotoClicker";
import VideoCapture from "./VideoCapture";
import ProgressBar from "../../posts/single-post/PostLoader/postLoader";
import { compressImage } from "../../upload/helpers";

const AttachmentModal = (props) => {
  const { t, i18n } = useTranslation();

  const [media, setMedia] = useState([]);
  const [docs, setDocs] = useState([]);
  const [startCamera, setCamera] = useState(false);
  const [load, setLoad] = useState(false);

  const [mediaType, setMediaType] = useState("");

  const [startVideoRecording, setVideoRecording] = useState(false);


  const setMediaFiles = (media) => {
    console.log('media', media);
    setMedia([...media]);
  };

  const setDocFiles = (docs) => {
    console.log('docs', docs);
    setDocs([...docs]);
  };

  console.log('props', props.clipboardData);

  // useEffect(() => {
  //   if(props?.clipboardData) {
  //     setMedia([...props.clipboardData])

  //   }
  // }, [props?.clipboardData])

  useEffect(() => {
    if (props?.clipboardData) {
      console.log("clipboard data", props?.clipboardData);
      const mediaData = props.clipboardData.filter(item => item.type === 'p' || item.type === 'v');
      const docData = props.clipboardData.filter(item => item.type === 'd')

      if(mediaData)
      setMedia([...mediaData]);

      if(docData)
      setDocs([...docData]);

      console.log('check clip', mediaData, docData)
    }
  }, [props?.clipboardData]);



  const onMediaSend = async (type, thumbnails, caption, isCompress) => {
    console.log("send media", { type, thumbnails });
    let param = []
    param = type === "documentUpload" ? docs : media;
    console.log('param', param)


    if (type === "mediaUpload")
      if (thumbnails?.length > 0) {
        console.log('ggg');
        param = []
        param = media.map((me) => {
          let temp = "";
          if (me.type === "v") {
            temp = thumbnails.find((data) => {

              return me.file.name === data.imageName;
              // return 0;
            });
          }
          console.log("temp", temp);
          if (temp) {
            me.fthumbnail = temp.thumb;
          } else {
            me.fthumbnail = "";
          }
          return me;
        });
      }

      for (let i = 0; i < param.length; i++) {
        let file = param[i].file;
        console.log('File size before compression: ', Math.round(file.size / 1024))
        console.log('tick', file.size, isCompress, file);
        // Compress file if needed
        if (isCompress && file.type.includes("image")) {
          console.log('enter');
          try {
            const compressedFile = await compressImage(file);
            console.log("File size after compression: ", Math.round(compressedFile.size / 1024));
            
            param[i].file = compressedFile;
            console.log(param[i].file.size, "tick")
          } catch (error) {
            console.error('Error compressing file:', error);
          }
        } else if(!isCompress){
          param[i].type = "d";
        }
      } 

    console.log("test para", param);
    props.handleMediaSend(param, caption);

  };




  const onSend = async (param, caption) => {
    props.handleMediaSend(param, caption);
    // }
  };

  const onRecordAudioClick = () => {
    props.onRecordAudioClick();
  };

  const onTakePhotoClick = () => {
    setCamera(true);
  };

  const onCaptureVideoClick = (data) => {
    setVideoRecording(true);
  };

  return (
    <>
      <Modal
        centered
        show
        onHide={props.handleClose}
        className="custom-modal attachment"
      >
        {!load && <IconButton
          clear="true"
          className="closeIcon"
          icon="close"
          handleClick={props.handleClose}
        />}
        {load ?
          <div style={{ minHeight: 400 }}>
            <ProgressBar />
          </div>
          : <Modal.Body>
            <div className="item-container">
              <FileList
                id={"mediaUpload"}
                fileTypes={".png, .jpg, .jpeg, .bmp, .gif, .webp, .mp4"}
                setFiles={setMediaFiles}
                files={media}
                text={t("share_media_title")}
                icon="add_a_photo"
                className="file-upload-btn"
                type="chat"
                previews={true}
                closeModal={props.handleClose}
                handleSend={(type, thumbnails, caption, isCompress) => onMediaSend(type, thumbnails, caption, isCompress)}
                onLoad={(status) => setLoad(status)}
                handleCompression = {props.handleCompression}
                onUpdateCompression= {props.onUpdateCompression}
              />

              <FileList
                id={"documentUpload"}
                fileTypes={".xlsx,.xls,.doc,.docx, .xml, .pdf"}
                setFiles={setDocFiles}
                previews={true}
                files={docs}
                text={t("share_docs_title")}
                icon="insert_drive_file"
                className="file-upload-btn"
                type="chat"
                closeModal={props.handleClose}
                single={true}
                handleSend={(type, param, caption) => onMediaSend(type, param, caption)}
              />
              {/* </div> */}
              <div className="item">
                <IconButton
                  text={t("record_audio_title")}
                  clear="true"
                  handleClick={() => onRecordAudioClick()}
                  className="fs-3"
                  icon="mic"
                />
              </div>
              <div className="item">
                <IconButton
                  text={t("take_photo_title")}
                  clear="true"
                  handleClick={() => onTakePhotoClick()}
                  className="fs-3"
                  icon="camera_alt"
                />
              </div>
              {/* <div className="item">
              <IconButton
                text={t("capture_video_title")}
                clear="true"
                handleClick={() => onCaptureVideoClick()}
                className="fs-3"
                icon="videocam"
              />
            </div> */}
            </div>

          </Modal.Body>}
      </Modal>


      {startCamera && (
        <PhotoClicker
          closeModal={props.handleClose}
          onSendClick={(param, caption) => onSend(param, caption)}
        />
      )}

      {startVideoRecording && (
        <VideoCapture
          closeModal={props.handleClose}
          onSendClick={(param, caption) => onSend(param, caption)}
        />
      )}
    </>
  );
};

export default AttachmentModal;
