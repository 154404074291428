import React, { Component } from "react";
import "./EditPost.scss";
import Button from "../../elements/buttons/Button";
import { Form, Modal } from "react-bootstrap";
import IconButton from "../../elements/buttons/IconButton";
import MentionTextarea from "../../elements/mention-textarea";
import Checkbox from "../../elements/checkbox";
import ConfirmPostDialog from "../../posts/confirmPostDialog/confirmPostDialog";
import {
  deleteMediaFromPost,
  deleteDocFromPost,
  savePost,
  removeCallToAction,
} from "../../../api/posts";
import FileList from "../../upload";
import Radio from "../../elements/radio";
import { getProfile } from "../../../api/user";
import { getPostData } from "../../../api/posts";
import { getGroupAbout } from "../../../api/groups";
import DynamicSearch from "../../elements/dynamic-search";
import { withTranslation } from "react-i18next";
import ButtonLoader from "react-loader-spinner";
import { Spinner } from "react-bootstrap";


import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import MyGroupModal from "../../groups/MyGroupModal";
import { object } from "prop-types";
import { withRouter } from "../../customNavigate/withRouter";
import { validateEmail, validateLink } from "../../../lib/utils/Commons";

//edit or create new post
const CalltoActionContainer = ({ clear, t, handleTextLink, handleMailTo }) => {
  return (
    <div className="callToActionWrapper">
      <div className="callToActionWrapper__item">
        <Button
          className={"inverted"}
          text={t("text_link")}
          handleClick={handleTextLink}
        />
      </div>
      <div className="callToActionWrapper__item">
        <Button
          className={"inverted"}
          text={t("mail_to")}
          handleClick={handleMailTo}
        />
      </div>
      <IconButton
        icon={"cancel"}
        outlined={true}
        clear={true}
        handleClick={clear}
      />
    </div>
  )
}


class EditPost extends Component {
  state = {
    subject: "",
    content: "",
    initContent: "",
    allowComments: true,
    message: "",
    mediaFiles: [],
    canAdminPublish: true,
    docFiles: [],
    isPremium: false,
    isNews: false,
    isStandard: true,
    LCU_mayor: "n",
    LCU_community: "n",
    saving: false,
    defaultGroups: [],
    groups: [],
    isLoading: false,
    showconfirmationDialog: false,
    shareToOtherGroupAllowed: false,
    canCreateNews: false,
    showCalltoAction: true,
    showTextLinkModal: false,
    showMailToModal: false,
    textLinkUrl:"",
    textLinkCtaName:"",
    mailToEmail:"",
    mailToSubject:"",
    mailToButtonText:"",
    mailToEmailText:"",
    
    thumbs: [],
    LCP_cta:{
      LPC_action: "", 
      LPC_title : "",
      LPC_link: "", 
      LPC_mail_subject : "",
      LPC_mail_text : ""
    },
    callToActionMessage:"",
    mentions: {},
    mappedMentions: [],
  };

  handleClose = () => {
    this.props.stopEdit();
  };

  setMediaFiles = (media, thumbData) => {
    this.setState({
      thumbs: thumbData,
      mediaFiles: media,
    });
  };

  setDocFiles = (docs) => {
    this.setState({ docFiles: docs });
  };

  handleGroup = (id) => {
    const groups = this.state.sharedGroups;
    if (groups.includes(id))
      this.setState({ sharedGroups: groups.filter((x) => x != id) });
    else this.setState({ sharedGroups: [...groups, id] });
  };

  componentDidMount() {
    let userDetails = JSON.parse(localStorage.getItem("user"));

    if (userDetails?.News === "y") {
      this.setState({
        canCreateNews: true,
      });
    }
    this.initProfileData();

    if (this.props.editing) {
      this.setState({
        initContent: "",
        mappedMentions: [],
      })
    }

    if (this.props.isEditable) {
      this.initEditablePost();
    }

    if (this.props.groupId && this.props.groupId !== 0) {
      this.initGroupData();
    }

    if (this?.props?.postData) {
      this.mappPostData()
    }
  }

  mappPostData() {
    const data = this.props.postData
        
    if(data.LCP_cta !== null){
      this.setState({LCP_cta:data.LCP_cta })
    }

    this.setState({
      subject: data.subject,
      content: data.content,
      allowComments: data.allowComments,
      show: true,
      mediaFiles: data.mediaFiles,
      docFiles: data.docFiles,
      //   id: data.loci_postID,
      isPremium: data.isPremium,
      isStandard: data.isStandard,
      groups: data.groups?.map((x) => x.id.toString()),
      shareToGroups: data.shareToGroups && data.shareToGroups.length > 0,
      canAdminPublish: data.canAdminPublish,
    });
  }

  initGroupData() {
    this.setState({ groupId: this.props.groupId });

    getGroupAbout(this.props.token, this.props.groupId).then((res) => {
      if (res.data.OK) {
        this.setState({
          admins: res.data.OK.LGP_admin,
          groupName: res.data.OK.LGP_name,
          shareToOtherGroupAllowed: res.data.OK.LGP_share_to_group === "y",
        });
      }
    });
  }

  getEditablePostData() {
    if (!this.props.editedPost) {
      throw new Error("No post data");
    }
    return getPostData(this.props.token, this.props.editedPost.loci_postID);
  }

  initEditablePost() {
    this.getEditablePostData().then((res) => {
      if (res.data.OK) {
        //const data = this.props.editedPost;F
        const data = res.data.OK;
        

        const media = [];
        for (let item of data.LCP_media) {
          const file = {
            preview: item.img,
            type: item.type,
            id: item.loci_imageID,
          };
          media.push(file);
        }

        const docs = [];
        for (let item of data.LCP_docs) {
          const file = { name: item.doc, type: "d", id: item.loci_docID };
          docs.push(file);
        }   
        if(data.LCP_cta !== null){
          this.setState({
         LCP_cta:data.LCP_cta 
          })
        }
         
        this.setState({
          subject: data.LCP_subject,
          content: data.LCP_mess1,
          allowComments: data.LCP_comment === "y" ? true : false,
          show: true,
          mediaFiles: media,
          docFiles: docs,
          id: data.loci_postID,
          isPremium: data.LCP_mayor === "y" ? true : false,
          isStandard: data.LCP_mayor === "n" ? true : false,
          groups: data.shareOtherGroup?.map((x) => x.id.toString()),
          shareToGroups:
          data.shareOtherGroup && data.shareOtherGroup.length > 0,
          canAdminPublish: data.LCP_admin_post === "y",
          initContent: data.LCP_mess1.replaceAll('\r\n', '\n'),
          mappedMentions: data.mentions,
        });
      }
    });
  }

  initProfileData() {
    getProfile(this.props.token).then((res) => {
      if (res.data.OK) {
        this.setState({
          LCU_mayor: res.data.OK.LCU_mayor === "y",
          LCU_community: res.data.OK.LCU_community === "y",
          userId: parseInt(res.data.userID),
        });
      }
    });
  }

  handleGroup = (id) => {
    var groups = this.state.groups;
    if (groups.includes(id)) {
      groups = groups.filter((x) => x != id);
    } else {
      groups.push(id);
    }
    this.setState({ groups: groups });
  };

  Loading = () => {
    this.setState({ isLoading: true });
  };

  submitForm = (e, confirmedPost) => {
    console.log('state', this.state, !this.state.subject);


    e?.preventDefault?.();

    this.setState({ message: "", saving: true });
    if (!this.state.subject) {
      const { t } = this.props;
      this.setState({ message: t("add_edit_post_topic") + " " + t("blank") });
      return false;
    }
    if (!confirmedPost) {
      this.setState({ showconfirmationDialog: true });
      return;
    }
    this.Loading();
    const delMedia = this.state.mediaFiles.filter((x) => x.id && x.delete);
    for (var i = 0; i < delMedia.length; i++)
      deleteMediaFromPost(this.props.token, this.state.id, delMedia[i].id);
    const delDocs = this.state.docFiles.filter((x) => x.id && x.delete);
    for (var i = 0; i < delDocs.length; i++)
      deleteDocFromPost(this.props.token, this.state.id, delDocs[i].id);
    savePost(this.props.token, this.state, this.props.isEditable)
      .then((res) => {
        if (res.data.OK) {
          if (this.props.isEditable) {
            return window.location.reload()
          }
          if (this.props.location.pathname === "/" && this.props.groupId !== 0) {
            return this.props.history.push("/group/" + this.props.groupId)
          }
          else if (this.props.location.pathname === "/" && this.props.groupData.id === 0) {
            return this.props.history.push("/public-feed");

          }

          else if (this.props.location.pathname.includes('group') && this.props.groupId === 0) {
            return this.props.history.push("/public-feed")
          }
          else if (this.props.location.pathname.includes('group') && this.props.groupId !== 0) {
            this.props.history.push("/group/" + this.props.groupId)
            return this.props.history.go(0)

          }
          else if (this.props.location.pathname === "/public-feed" && this.props.groupId !== 0) {
            return this.props.history.push("/group/" + this.props.groupId)
          }


          window.location.reload(false);
        } else this.setState({ message: res.data.ER });
      })
      .finally(() => this.setState({ saving: false, isLoading: false }));
  };

  getGroupType = (group) => {
    const { t } = this.props;

    if (group?.community === "y") {
      return t("community_feed")
    }
    else if (group?.public === "y") {
      return t("public_grp")
    }
    else if (group?.public === "n") {
      return t("private_grp")
    }
    else {
      return t("public_feed_title")
    }
  }    

  handleCloseCallActionModal = (type) =>{ 
    console.log("calling");
  if(type === "textLink"){
         this.setState({
          showTextLinkModal:!this.state.showTextLinkModal ,
          textLinkCtaName:"",
          textLinkUrl:"",
          callToActionMessage:""
         })
  }else{
    this.setState({
      showMailToModal:!this.state.showMailToModal,
        mailToButtonText:"",
        mailToEmail:"",
        mailToSubject:"",
        mailToEmailText:"",
        callToActionMessage:""
     })
  }  

  } 

  submitCallToAction = (e,type) =>{
    const { t } = this.props;    
    
    e.preventDefault()
      if(type==="textLink"){  
            
        if(!validateLink(this.state.textLinkUrl)){
          this.setState({
            callToActionMessage: t("not_valid_link"),
          }); 
          return
        }
          
        this.setState({ 
          LCP_cta: {
              LPC_action: "link",
              LPC_title: this.state.textLinkCtaName, // Assuming this.state.textLinkCtaName contains the title
              LPC_link: this.state.textLinkUrl // Assuming this.state.textLinkUrl contains the link URL
          } ,
          textLinkCtaName:"",
          textLinkUrl:"",
          showTextLinkModal:!this.state.showTextLinkModal,
          callToActionMessage:"",
          showCalltoAction: !this.state.showCalltoAction
      });
      } else{  
        if (!validateEmail(this.state.mailToEmail)) {
          this.setState({
            callToActionMessage: t("not_valid_email"),
          }); 
          return
  }   

        this.setState({ 

          LCP_cta: {
              LPC_action: "mailto",
              LPC_title: this.state.mailToButtonText, // Assuming this.state.textLinkCtaName contains the title
              LPC_link: this.state.mailToEmail,
              LPC_mail_subject:this.state.mailToSubject,
              LPC_mail_text : this.state.mailToEmailText
          } , 
          mailToButtonText:"",
          mailToEmail:"",
          mailToSubject:"",
          mailToEmailText:"",
          showMailToModal:!this.state.showMailToModal,
          callToActionMessage:"",
          showCalltoAction: !this.state.showCalltoAction
        })
      } 
  
      console.log(this.state.LCP_cta,this.state.textLinkCtaName,"lcp_cta");

  }  
 
  handleContentChange = (newValue, mentions) => {
    this.setState({ content: newValue });

    let newMentions = {};

    mentions.map(mention => {
      const key = `@${mention.display}`;
      const value = `@[${mention.id}]`;

      newMentions[key] = value;
    });

    this.setState({mentions: newMentions});

  };
   
  deleteCallToAction = () =>{
        console.log("delete call to action"); 
        removeCallToAction(this.state.id,this.props.token).then((res)=>{
              if(res.data.OK){ 

               this.setState({
                LCP_cta:{
                  LPC_action: "", 
                  LPC_title : "",
                  LPC_link: "", 
                  LPC_mail_subject : "",
                  LPC_mail_text : ""
                } 
              })
              } 
        })
  }  

  render() {
    //public feed and mayor / group and admin
    const canPostPremium =
      (!this.state.groupId && this.state.LCU_mayor) ||
      (this.state.admins &&
        this.state.admins.some((x) => x.FK_userID === this.state.userId));

    const isPublicFeed = !(this.state.groupId && this.state.groupId !== 0);


    const { t, token } = this.props;

    const group = this.props.groupData
    
    const { content } = this.state

    return (
      <>
        <Modal
          centered
          show={this.props.editing}
          onHide={this.handleClose}
          backdrop="static"
        >
          <Modal.Body>
            <IconButton
              clear="true"
              className="icon-mr-0 close-modal"
              icon="close"
              handleClick={this.handleClose}
            />


            {!this.state.showconfirmationDialog && (
              <>
                {group !== null && group !== undefined &&
                  <div className="single-group cursor" style={{ padding: 0, marginBottom: 15 }} onClick={() => this.props.onEditGrpClick(this.state)}>
                    <div className="single-group-header">
                      <img
                        src={
                          group.userpic ? group.userpic : "/images/profile.png"
                        }
                      />
                      <div>

                        <span
                          className="bold cursor"

                        >
                          {group.name}
                        </span>

                        <div style={{ fontSize: 12 }}>
                          {this.getGroupType(group)}

                        </div>
                      </div>

                    </div>
                    <div>
                      <i className="material-icons ml-5">
                        edit
                      </i>
                    </div>
                  </div>
                }
                <Form className="newPostForm" onSubmit={(e)=>this.submitForm(e,"")}>
                  <Form.Group className="form-group" size="lg" controlId="topic">
                    <Form.Label>{t("add_edit_post_topic")}</Form.Label>
                    <Form.Control
                      autoFocus
                      type="text"
                      value={this.state.subject}
                      onChange={(e) => this.setState({ subject: e.target.value.trimStart() })}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="form-group" size="lg" controlId="content">
                    <Form.Label>{t("add_edit_post_description")}</Form.Label>
                    <MentionTextarea
                      token={token}
                      value={this.state.initContent}
                      preMentions={this.state.mappedMentions}
                      onChange={this.handleContentChange}
                    />
                  </Form.Group>  

                   {this.state.LCP_cta.LPC_title !== "" && ( <div className="form-group" >
                    <IconButton
                     icon={"cancel"}
                     outlined={true} 
                     text={this.state.LCP_cta.LPC_title}
                     iconRight={true} 
                     iconClick={()=> this.deleteCallToAction()}
                     className="w-100 btn-secondary justify-content-center" 
                    />
                  </div> ) } 

                  <FileList
                    id={"mediaUpload"}
                    setFiles={this.setMediaFiles}
                    previews={true}
                    fileTypes={".png, .jpg, .jpeg, .bmp, .gif,  .mp4, .webp"}
                    icon="add_a_photo"
                    files={this.state.mediaFiles}
                    className="icon-right"
                    ref={this.child}
                  />

                  <FileList
                    id={"documentUpload"}
                    fileTypes={
                      ".png, .jpg, .jpeg, .bmp, .gif,  .mp4, .doc, .docx, .xml, .pdf"
                    }
                    setFiles={this.setDocFiles}
                    files={this.state.docFiles}
                    text={t("add_edit_post_document")}
                    icon="attach_file"
                    ref={this.child}
                  /> 

                  {this.state.LCP_cta.LPC_title === "" && (<div className="form-group" >
                    {this.state.showCalltoAction  ? <IconButton
                      className={"inverted"}
                      icon={"add"}
                      text={t("call_to_action")}
                      handleClick={() => this.setState({ showCalltoAction: !this.state.showCalltoAction })}
                    /> : <CalltoActionContainer
                      t={t}
                      clear={() => this.setState({ showCalltoAction: !this.state.showCalltoAction })}
                      handleMailTo={() => this.setState({ showMailToModal: !this.state.showMailToModal })}
                      handleTextLink={() => this.setState({ showTextLinkModal: !this.state.showTextLinkModal })}
                    />}
                  </div>) }

                  {canPostPremium && (
                    <Accordion style={{ boxShadow: 'none' }} className="accordion">
                      <AccordionSummary className="accordionSummary"
                        expandIcon={<i className="material-icons green ml-5">expand_more</i>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className="accordianSetting">{t("add_edit_post_type")}</Typography>
                      </AccordionSummary>
                      <AccordionDetails className="accordionDetails">

                        <div>

                          <Radio
                            value="standard"
                            selected={this.state.isStandard ? "standard" : "premium"}
                            text={t("add_edit_post_type_standard")}
                            onChange={() =>
                              this.setState({
                                isStandard: true,
                                isNews: false,
                                isPremium: false,
                              })
                            }
                          />
                          <Radio
                            value="premium"
                            selected={this.state.isPremium ? "premium" : "standard"}
                            text={t("add_edit_post_type_premium")}
                            onChange={() =>
                              this.setState({
                                isPremium: true,
                                isStandard: false,
                                isNews: false,
                              })
                            }
                          />

                          {this.state.canCreateNews && (
                            <Radio
                              value="news"
                              selected={this.state.isNews ? "news" : ""}
                              text={t("news_title")}
                              onChange={() =>
                                this.setState({
                                  isNews: true,
                                  isPremium: false,
                                  isStandard: false,
                                })
                              }
                            />
                          )}

                        </div>
                      </AccordionDetails>
                    </Accordion>
                  )}

                  <Accordion style={{ boxShadow: 'none' }} className="accordion">
                    <AccordionSummary className="accordionSummary"
                      expandIcon={<i className="material-icons green ml-5">expand_more</i>}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className="accordianSetting">{t("settings")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="accordionDetails">
                      <div>
                        {isPublicFeed &&
                          this.state.LCU_community &&
                          !this.state.isPremium &&
                          this.props.isEditable && (
                            <>
                              <Form.Group
                                size="lg"
                                controlId="allow"
                                className="mb-0"
                              >
                                <Checkbox
                                  text={t("add_edit_post_settings_post_to_community")}
                                  value={this.state.isSendToCommunity}
                                  handleChange={(e) =>
                                    this.setState({
                                      isSendToCommunity:
                                        !this.state.isSendToCommunity,
                                    })
                                  }
                                />
                              </Form.Group>
                              {this.state.isSendToCommunity && (
                                <Form.Group
                                  size="lg"
                                  controlId="allow"
                                  className="mb-0"
                                >
                                  <Checkbox
                                    text={t(
                                      "add_edit_post_settings_visible_for_others"
                                    )}
                                    value={this.state.isVisibleToUsers}
                                    handleChange={(e) =>
                                      this.setState({
                                        isVisibleToUsers:
                                          !this.state.isVisibleToUsers,
                                      })
                                    }
                                  />
                                </Form.Group>
                              )}
                            </>
                          )}
                        {!isPublicFeed && (
                          <>
                            <Form.Group size="lg" className="mb-0">
                              <Checkbox
                                text={t(
                                  "add_edit_post_settings_visible_to_group_admins"
                                )}
                                value={this.state.isVisibleToAdmins}
                                handleChange={(e) =>
                                  this.setState({
                                    isVisibleToAdmins: !this.state.isVisibleToAdmins,
                                    canAdminPublish: false,
                                    shareToGroups: false,
                                  })
                                }
                              />
                            </Form.Group>
                            <Form.Group size="lg" className="mb-0">
                              <Checkbox
                                text={t("add_edit_post_settings_admin_publish")}
                                value={this.state.canAdminPublish}
                                handleChange={(e) =>
                                  this.setState({
                                    canAdminPublish: !this.state.canAdminPublish,
                                    isVisibleToAdmins: false,
                                    shareToGroups: false,
                                  })
                                }
                              />
                            </Form.Group>
                          </>
                        )}
                        <Form.Group size="lg" className="mb-0">
                          <Checkbox
                            text={t("add_edit_post_settings_allow_comments")}
                            value={this.state.allowComments}
                            handleChange={(e) =>
                              this.setState({
                                allowComments: !this.state.allowComments,
                              })
                            }
                          />
                        </Form.Group>

                        {!isPublicFeed && this.state.shareToOtherGroupAllowed && this.state.canAdminPublish && (
                          <Form.Group size="lg" className="mb-0">
                            <Checkbox
                              text={t("add_edit_post_settings_share_post")}
                              value={this.state.shareToGroups}
                              handleChange={(e) => {
                                this.setState({
                                  shareToGroups: !this.state.shareToGroups,
                                  isVisibleToAdmins: false,
                                  // canAdminPublish: false,
                                });
                              }}
                            />
                          </Form.Group>
                        )}
                        {this.state.shareToGroups && (
                          <>
                            <h3>
                              {t("share_to_groups_screen_title_primary") +
                                t("share_to_groups_screen_title_secondary")}
                            </h3>
                            <Form.Group
                              size="lg"
                              controlId="groupAdmins"
                              className="group-admins"
                            >
                              <DynamicSearch
                                type="groups"
                                list={this.state.groups}
                                groupId={this.props.groupId}
                                handleList={(id) => this.handleGroup(id)}
                                token={this.props.token}
                              // groupId={this.props}
                              />
                            </Form.Group>
                          </>
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>



                  <div className="actions">
                    <Button
                      text={t("add_edit_post_create")}
                      type="submit"
                      className="w-100"
                    />
                  </div>
                </Form>
              </>
            )}
            {this.state.showconfirmationDialog && (
              <ConfirmPostDialog
                create={true}
                deleting={true}
                handleClose={() =>
                  this.setState({ showconfirmationDialog: false })
                }
                groupName={this.state.groupName}
                onSubmit={() => {
                  this.submitForm(null, true);
                }}
              />
            )} 

{this.state.showTextLinkModal && (
              <Modal
                centered
                show={this.state.showTextLinkModal}
                // onHide={this.handleCloseCallActionModal("textLink")}
                backdrop="static"
              >
                <Modal.Body>
                  <IconButton
                    clear="true"
                    className="icon-mr-0 close-modal"
                    icon="close"
                    handleClick={() => this.handleCloseCallActionModal("textLink")}
                  /> 
                 <div className="modalHeader">
                  <h3>{t("text_link")}</h3>
                  <p>{t("text_link_description")}</p>
                 </div>
                <Form className="newPostForm" onSubmit={(e)=>this.submitCallToAction(e,"textLink")}>
                    <Form.Group className="form-group" size="lg" controlId="content">
                    <Form.Label>{t("url")+" "+"*"}</Form.Label>
                    <Form.Control
                      autoFocus
                      type="text"
                      value={this.state.textLinkUrl}
                      required
                      onChange={(e) => this.setState({ textLinkUrl: e.target.value })}
                    />
                  </Form.Group> 
                  <Form.Group className="form-group" size="lg" controlId="content">
                    <Form.Label>{t("cta_name")}</Form.Label>
                    <Form.Control
                      autoFocus
                      type="text"
                      value={this.state.textLinkCtaName}
                      required
                      onChange={(e) => this.setState({ textLinkCtaName: e.target.value })}
                    />
                  </Form.Group> 
                  <div className="actions">
                    <Button
                      text={t("create_post_name_primary")}
                      type="submit"
                      className="w-100"
                    />
                  </div>
                  </Form> 
                  {this.state.callToActionMessage && (
              <span className="formMessage">{this.state.callToActionMessage}</span>
            )}
           
                </Modal.Body>
              </Modal>
            )} 
              {this.state.showMailToModal && (
              <Modal
                centered
                show={this.state.showMailToModal}
                // onHide={this.handleCloseCallActionModal("textLink")}
                backdrop="static"
              >
                <Modal.Body>
                  <IconButton
                    clear="true"
                    className="icon-mr-0 close-modal"
                    icon="close"
                    handleClick={() => this.handleCloseCallActionModal("")}
                  /> 
                 <div className="modalHeader">
                  <h3>{t("mail_to")}</h3>
                 </div>
                <Form className="newPostForm" onSubmit={(e)=>this.submitCallToAction(e,"mailTo")}>
                <Form.Group  className="form-group" size="lg" controlId="mailToButtonText">
                    <Form.Label>{t("mailto_button_text")+" "+"*"}</Form.Label>
                    <Form.Control
                      autoFocus
                      type="text"
                      value={this.state.mailToButtonText}
                      required
                      onChange={(e) => this.setState({ mailToButtonText: e.target.value })}
                    />
                  </Form.Group> 
                    <Form.Group className="form-group" size="lg" controlId="mailToemail">
                    <Form.Label>{t("email")+" "+"*"}</Form.Label>
                    <Form.Control
                      autoFocus
                      type="text"
                      value={this.state.mailToEmail}
                      required
                      onChange={(e) => this.setState({ mailToEmail: e.target.value })}
                    />
                  </Form.Group> 
                  <Form.Group className="form-group" size="lg" controlId="mailToSubject">
                    <Form.Label>{t("mailto_subject")}</Form.Label>
                    <Form.Control
                      autoFocus
                      type="text"
                      value={this.state.mailToSubject}
                      onChange={(e) => this.setState({ mailToSubject: e.target.value })}
                    />
                  </Form.Group> 
                  <Form.Group className="form-group" size="lg" controlId="mailToEmailText">
                    <Form.Label>{t("mailto_email_text")}</Form.Label>
                    <Form.Control
                      autoFocus
                      as="textarea"
                      maxLength={3000}
                      value={this.state.mailToEmailText}
                      rows={2}
                      onChange={(e) => this.setState({ mailToEmailText: e.target.value })}
                    />
                  </Form.Group> 
                  <div className="actions">
                    <Button
                      text={t("create_post_name_primary")}
                      type="submit"
                      className="w-100"
                    />
                  </div>
                  </Form> 
                  {this.state.callToActionMessage && (
              <span className="formMessage">{this.state.callToActionMessage}</span>
            )}
                      
                </Modal.Body>
              </Modal>
            ) }
           
            {this.state.message && (
              <span className="formMessage">{this.state.message}</span>
            )}

          </Modal.Body>
        </Modal>

        {this.state.isLoading && (
          <div className="sending-overlay">
            <div class="loader">
              <Spinner animation="border" role="status" color="#00395c">
                <span className="sr-only">{t("loading")}...</span>
              </Spinner>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default withTranslation()(withRouter(EditPost));

