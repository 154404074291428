import React, { Component } from "react";
import Button from "../../elements/buttons/Button";
import "rc-slider/assets/index.css";
import "../Inbox.scss";
import UserCard from "../../elements/cards/UserCard";
import { withRouter } from "../../customNavigate/withRouter";
import { getGroupAdmins } from "../../../api/common";
import { connect } from "react-redux";
import { mapStateToProps } from "lib/store/mapStateToProps";
import { withTranslation } from "react-i18next";
import BackButton from "../../commons/BackButton";
import Loader from "../../../components/posts/single-post/PostLoader/postLoader.js";
import UserDetails from "../../user-details/index";

class NewChat extends Component {
  state = {
    newChat: false,
    list: [],
    query: "",
    isLoading: true,
    startIndexOfUsers: 0,
    endIndexOfUsers: 15,
    isLoadMoreUsers: false,
    totalUsers: null,
  };

  componentDidMount() {
    this.getInboxList();

    window.addEventListener("scroll", this.handleScroll);

    return () => {
      window.removeEventListener("scroll", this.handleScroll);
    };
  }

  componentWillUnmount() {}

  _this = this;
  handleScroll = (e) => {
    if (window.scrollY > 0) {
      if (this.state.endIndexOfUsers < this.state.totalUsers) {
        this.setState((prevState) => ({
          endIndexOfUsers: prevState.endIndexOfUsers + 15,
          isLoadMoreUsers: false,
        }));
      }
    }
  };

  getInboxList() {
    //  getGroupAdmins(this.props.token, this.props.query).then(res => this.setState({list: res.data.row}))

    getGroupAdmins(this.props.token, this.props.query).then((res) => {
      if (res.data.row) {
        const data = res.data.row;
        let modifiedData;
        let userDetails = JSON.parse(localStorage.getItem("profile"));
        if (userDetails) {
          console.log(userDetails.userId);
          modifiedData = data.filter(
            (me) => me.FK_userID !== userDetails.userId
          );
        }
        this.setState({
          list: modifiedData,
          totalUsers: modifiedData.length,
          isLoading: false,
        });
      }
    });
  }

  componentDidUpdate() {
    if (this.state.query != this.props.query) {
      this.setState({ query: this.props.query });
      this.getInboxList();
    }
  }

  onCardClick(e, clickedOn, data) {
    e.stopPropagation();
    if (clickedOn === "img") {
      this.setState({
        userId: data.FK_userID,
        showUserDetails: true,
      });
    } else {
      this.props.history.push({
        pathname: `/chat/${data.FK_userID}`,
        state: { grpCreated: true },
      });
    }
  }

  onMsgClick(e, data) {
    e.stopPropagation();
    this.props.history.push({
      pathname: `/chat/${data.FK_userID}`,
      state: { grpCreated: true },
    });
  }

  render() {
    const { t } = this.props;
    return (
      <>
        {this.state.isLoading ? (
          <Loader />
        ) : (
          <div className="inbox main-content">
            <div className="main-content-narrow">
              {this.state.list.length > 0 ? (
                <div className="mt-3 mb-2">
                  <BackButton />
                </div>
              ) : (
                ""
              )}

              {this.state.list &&
                this.state.list
                  .slice(
                    this.state.startIndexOfUsers,
                    this.state.endIndexOfUsers
                  )
                  .map((x, i) => (
                    <UserCard
                      item={x}
                      className={
                        x.LCM_read === "y" ? "read cursor" : "unread cursor"
                      }
                      handleClick={(e, clickedOn) =>
                        this.onCardClick(e, clickedOn, x)
                      }
                    >
                      <Button
                        text={t("plus_message_button_title")}
                        clear={true}
                        handleClick={(e) => this.onMsgClick(e, x)}
                      />
                    </UserCard>
                  ))}
            </div>
          </div>
        )}

        {this.state.showUserDetails && (
          <UserDetails
            id={this.state.userId}
            {...this.props}
            handleClose={() =>
              this.setState({
                showUserDetails: false,
              })
            }
          />
        )}
      </>
    );
  }
}

export default withTranslation()(connect(mapStateToProps)(withRouter(NewChat)));
