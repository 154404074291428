import React from "react";
import IconLink from "../buttons/IconLink";
import Button from "../buttons/Button";
import "./UserCard.scss";
import { LocciAvatar } from "../../commons/LocciAvatar";
import { useLocation } from "react-router-dom";

function UserCard({ item, children, dataChild, className, handleClick, img }) {
  const classes = className ? className : "";

  const location = useLocation()

  let imgSrc;

  if (img === undefined) {
    imgSrc = item.CAR_avatar
      ? item.CAR_avatar
      : item.USE_pic
      ? item.USE_pic
      : item.avatar
      ? item.avatar
      : "/images/profile.png";
  } else {
    imgSrc = img ? img : "";
  }

  return (
    <div
      className={"user-card" + " " + classes}
      onClick={(e) => handleClick? handleClick(e, "div"):""}
    >
      <div className="user-card-content">
        {/* <img src={imgSrc} onClick={(e) => handleClick(e, "img")} /> */}


        <LocciAvatar
          title={item.CAR_room || (item?.USE_firstname + " " + item?.USE_surename)}
          picture={imgSrc}
          size={"60px"}
          onAvatarClick={(e) => handleClick? handleClick(e, "img"):""}
          className="cursor"
        />
        <div>
          {!location.pathname.includes("notifications") && <span className="bold">
            {item?.CAR_room
              ? item?.CAR_room
              : item.USE_firstname + " " + item.USE_surename}
          </span>}
          {dataChild}
        </div>
      </div>
      <div className="user-card-actions">{children}</div>
    </div>
  );
}
export default UserCard;
