import { HeartIcon } from "components/icons/Heart";
import { HeartOutlineIcon } from "components/icons/HeartOutline";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { favoritePost, likePost } from "../../../api/posts.js";
import Button from "../../elements/buttons/Button.js";
import SharePostDialog from "../../posts/sharePostDialog/sharePostDialog.js";
import { ActionIcon } from "./ActionIcon";
import PostComments from "./PostComments";
import { PostActionButton, PostActionsWrapper } from "./style";

const PostActions = (props: { 
  token: string; 
  post: any; 
  isMember: boolean;
  userDetailsAction: (id: number, post?: any) => void;
  groupInfoAction: (id: string) => void;
}) => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const [clickedButton, setClickedButton] = useState({} as any);

  const [likes, setLikes] = useState(props.post.likeAnz);
  const [isLiked, setLiked] = useState(Number(props.post.mylikeAnz) === 1);

  const [favorites, setFavorites] = useState(props.post.followAnz);
  const [isFavorite, setFavorite] = useState(Number(props.post.myfollowAnz) === 1);

  const [comments, setComments] = useState(Number(props.post.comAnz));
  const isCommented = Number(props.post.mycomAnz) === 1;
  const [showComments, setShowComments] = useState(false);

  const [sharePost, setSharePost] = useState(false);
  const [showNotGroupMember, setShowNotGroupMember] = useState(false);

  const [actionsNotAllowed, setActionsNotAllowed] = useState(false);
  const isMyPost = props.post?.my === "y" && !(props.post.OrgGroupId !== null && props.post.OrgGroupId !== "");
  const isShareable = props.post.LGP_shareable !== "n";

  useEffect(() => {
    if (searchParams.get("redirectFrom") === "embedded") {
      setActionsNotAllowed(true);
    }
  }, []);

  const handleLike = () => {
    if (!props.isMember) {
      setShowNotGroupMember(true);
      return;
    }
    setClickedButton({ ...clickedButton, like: true });

    likePost(props.token, props.post.loci_postID, !isLiked)
      .then((res) => {
        if (res.data.OK) {
          if (isLiked) {
            setLikes(Number(likes) - 1);
            setLiked(false);
          } else {
            setLiked(true);
            setLikes(Number(likes) + 1);
          }
        }
      })
      .finally(() => setClickedButton({ ...clickedButton, like: false }));
  };

  const handleFavorite = () => {
    if (!props.isMember) {
      setShowNotGroupMember(true);
      return;
    }
    setClickedButton({ ...clickedButton, favorite: true });

    favoritePost(props.token, props.post.loci_postID, !isFavorite)
      .then((res) => {
        if (res.data.OK) {
          if (isFavorite) {
            setFavorites(Number(favorites) - 1);
            setFavorite(false);
          } else {
            setFavorite(true);
            setFavorites(Number(favorites) + 1);
          }
        }
      })
      .finally(() => setClickedButton({ ...clickedButton, favorite: false }));
  };

  const toggleComments = () => {
    if (!props.isMember) {
      setShowNotGroupMember(true);
      return;
    }
    setShowComments((value) => !value);
  };

  const handleShare = () => {
    if (!props.isMember) {
      setShowNotGroupMember(true);
      return;
    }

    setSharePost(true);
  };

  const onShowUserDetails = (userId: string) => {
    // eslint-disable-next-line no-console
    console.log(userId);
    // setShowComments((value) => !value);
  };

  const onCommentsCountChanged = (direction: "UP" | "DOWN") => {
    if (direction === "UP") setComments((comments) => comments + 1);
    if (direction === "DOWN") setComments((comments) => comments - 1);
  };

  return (
    <>
      <PostActionsWrapper $notAllowed={actionsNotAllowed}>
        <PostActionButton
          name='like'
          disabled={clickedButton.like || isMyPost}
          onClick={handleLike}
        >
          {isLiked ? <HeartIcon /> : <HeartOutlineIcon />} {likes || "0"}
        </PostActionButton>
        <PostActionButton name='comment' onClick={toggleComments}>
          <ActionIcon type='comment' filled={isCommented} /> {comments || "0"}
        </PostActionButton>
        <PostActionButton
          name='bookmark'
          disabled={clickedButton.favorite || isMyPost}
          onClick={handleFavorite}
        >
          <ActionIcon type='favorite' filled={isFavorite} /> {favorites || "0"}
        </PostActionButton>
        {isShareable && (
          <PostActionButton name='share' style={{ marginLeft: "auto" }} onClick={handleShare}>
            <ActionIcon type='share' />
          </PostActionButton>
        )}
      </PostActionsWrapper>
      <PostComments
        token={props.token}
        showComments={showComments}
        post={props.post}
        showUserDetails={onShowUserDetails}
        commentsCountChanged={onCommentsCountChanged}
        userDetailsAction={props.userDetailsAction} 
        groupInfoAction={props.groupInfoAction}
      />

      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore TO DO: remove this ignore after SharePostDialog is converted to .tsx */}
      <SharePostDialog
        showModal={sharePost}
        shareUrl={props.post.Share}
        handleClose={() => setSharePost(false)}
      />
      <Modal show={showNotGroupMember}>
        <Modal.Body className='text-center'>{t("action_message_not_group_member")}</Modal.Body>
        <Modal.Footer>
          <Button
            text={t("close")}
            clear='true'
            handleClick={() => setShowNotGroupMember(false)}
          ></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PostActions;
