import { useEffect } from "react";
import * as Styled from "./styled";

type Props = {
  children: React.ReactNode;
  isOpen: boolean;
  className?: string;
  rounded?: boolean;
  onClose: () => void;
};

export const Modal = ({ isOpen, children, className, rounded, onClose }: Props) => {
  useEffect(() => {
    if (!isOpen) return;
    const originalStyle = window.getComputedStyle(document.body).overflow;
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, [isOpen]);

  return (
    <Styled.Modal $isOpen={isOpen} className={className}>
      <Styled.ModalShadow onClick={onClose} />
      <Styled.ModalContent $rounded={rounded}>
        <Styled.CloseButton onClick={onClose} />
        {children}
      </Styled.ModalContent>
    </Styled.Modal>
  );
};
