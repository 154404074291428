/* eslint-disable default-case */
import React, { Component } from "react";
import "./Login.scss";
import Button from "../../components/elements/buttons/Button";
import { Form, Modal } from "react-bootstrap";
import {
  getMunicipality,
  getStatesAndLanguages,
  registerUser,
} from "../../api/user";
import IconLink from "../../components/elements/buttons/IconLink";
import Checkbox from "../../components/elements/checkbox";
import DropdownSearch from "../../components/elements/dropdown-search";
import { withTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import IconButton from "../../components/elements/buttons/IconButton";
import { validatePromoCode } from "../../api/posts";
import {  validateEmail, validatePassword } from "lib/utils/Commons";


class Register extends Component {
  constructor(props) {
    super(props);
    this.postcodeOffset = React.createRef();
    this.totalPostcode = React.createRef()
  }
  state = {
    email: "",
    message: "",
    password: "",
    confirmPassword: "",
    countries: {},
    languages: {},
    myState: 1,
    myLang: 1,
    postcode: "",
    promocode: "",
    errorMsg: "",
    showErrorModal: false,
    inviteCode: "0",
    isPromoCodeValid: false,
    groupId: null,
    regTown: null,
    noDataFoundMsg: '',
    showLocationMsg: false,
    municipalities: []
  };

  componentDidMount() {
    getStatesAndLanguages().then((res) => {
      if (res.data.OK) {
        this.setState({
          languages: res.data.OK.lang,
          countries: res.data.OK.state,
        });
      }
    });
    if (localStorage.getItem("groupCode")) {
      this.setState({
        inviteCode: localStorage.getItem("groupCode"),
      });
    }

    let browserLang = navigator.language || navigator.userLanguage

    const selectedLang = browserLang?.includes("en")
      ? 1
      : browserLang?.includes("de")
        ? 2
        : browserLang?.includes("hu")
          ? 8
          : 2;

    this.setState({
      myLang: selectedLang
    })
  }


  validateForm = async (e) => {
    e.preventDefault();
    this.setState({ message: "" });
    const { t } = this.props;
    if (!validatePassword(this.state.password)) {
      this.setState({
        message: t("not_valid_password"),
        showErrorModal: true,
      });
    }

    else if (this.state.regTown === null) {
      this.setState({
        message: t("invalid_postcode"),
        showErrorModal: true,
      });
    } else if (!this.state.isGDPR) {
      this.setState({
        message: t("did_not_agree"),
        showErrorModal: true,
      });
    }
    else if (!validateEmail(this.state.email.trim())) {
      this.setState({
        message: t("not_valid_email"),
        showErrorModal: true,
      });
    }
    else {
      registerUser(this.state).then((res) => {
        if (res.data.ER) {
          this.setState({
            showErrorModal: true,
            message: res.data.ER,
          });
          console.log(this.state.message);
        } else if (res.data.OK) {
          localStorage.getItem("groupCode") &&
            localStorage.setItem(
              "newUserRegister",
              this.state.firstname.trim() + " " + this.state.lastname.trim()
            );
          this.setState({ successShow: true });
        }
      });
    }
  };

  getLocationFromPost(code, state) {
    const { t } = this.props;
    if (this.postcodeOffset.current !== this.totalPostcode.current) {
      getMunicipality(code, state, this.postcodeOffset.current).then((res) => {
        if (res.data.OK && res.data.plz.length > 0) {
          this.setState({
            municipalities: [...this.state.municipalities, ...res.data.plz],
            message: '',
            noDataFoundMsg: '',
            showLocationMsg: false
          });
          this.postcodeOffset.current = res.data.offset
          this.totalPostcode.current = res.data.total
        } else {
          this.setState({
            municipalities: [], regTown: null,
            noDataFoundMsg: t("no_result_found"),
            showLocationMsg: true
          })


        }
      });
    }
  }
  // showConfirmModal = (group) => {
  onTermsClick = (param) => {
    switch (param) {
      case "terms":
        if (i18n.language !== "en-GB") {
          const language = i18n.language.split("-")[0];

          this.setState({
            link: "/agb_" + language,
          });
        } else
          this.setState({
            link: "/agb_en",
          });
        break;
      case "privacy":
        if (i18n.language !== "en-GB") {
          const language = i18n.language.split("-")[0];

          this.setState({
            link: "/privacy_" + language,
          });
        } else
          this.setState({
            link: "/privacy_en",
          });
        break;
    }
    this.setState({
      showTermsModal: true,
    });
  };

  checkPromoCode(e) {
    e.preventDefault();

    const { t } = this.props;
    if (this.state.promocode.trim() !== "" && this.state.promocode.trim() !== null) {
      validatePromoCode(this.state.promocode.trim()).then((res) => {
        if (res.data.OK) {
          this.setState({
            isPromoCodeValid: true,
            groupId: res.data.OK,
            firstname: "",
          });
        } else {
          this.setState({
            message: res.data.ER,
            showErrorModal: true,
          });
        }
      });
    } else {
      this.setState({
        message: t("register_invalid_code"),
        showErrorModal: true,
      });
    }
  }

  onCountryChange(e) {
    this.setState({ myState: e.target.value });
    this.setState({ postcodeCity: "", municipalities: [], regTown: null });
    this.postcodeOffset.current = 0
    this.getLocationFromPost(
      this.state.postcode,
      e.target.value
    );
  }



  render() {
    const { t } = this.props;
    const states = this.state.countries;
    const languages = this.state.languages;

    const myState = this.state.myState ? this.state.myState : 1;
    const myLang = this.state.myLang;

    let browserLang = navigator.language || navigator.userLanguage

    const selectedLang = browserLang?.includes("en")
      ? 1
      : browserLang?.includes("de")
        ? 2
        : browserLang?.includes("hu")
          ? 8
          : 2;


    return (
      <div className="loginPage register">
        <div className="loginBox">
          {!this.state.isPromoCodeValid && this.state.inviteCode === "0" ? (
            <>
              <span>{t("register_with_promo")}</span>
              <Form
                className="loginForm mt-10"
                onSubmit={(e) => this.checkPromoCode(e)}
              >
                <Form.Group size="lg" controlId="promocode">
                  <Form.Label>{t("promo_code")}</Form.Label>
                  <Form.Control
                    type="text"
                    value={this.state.promocode}
                    onChange={(e) =>
                      this.setState({ promocode: e.target.value })
                    }
                    required
                  />
                </Form.Group>
                <Button
                  text={t("validate_promocode")}
                  type="submit"
                  className="w-100 mt-30"
                />
                {/* this.setState({isPromoCodeValid:true} */}
              </Form>
            </>
          ) : (
            <Form className="loginForm" onSubmit={(e) => this.validateForm(e)}>
              <Form.Group size="lg" className="form-group" controlId="firstname">
                <Form.Label style={{ marginBottom: 0 }} >{t("name") + "*"}</Form.Label>
                <Form.Control
                  required
                  type="text"
                  value={this.state.firstname}
                  onChange={(e) => this.setState({ firstname: e.target.value })}
                />
              </Form.Group>

              <Form.Group size="lg" className="form-group" controlId="lastname">
                <Form.Label style={{ marginBottom: 0 }} >{t("lastname") + "*"}</Form.Label>
                <Form.Control
                  required
                  type="text"
                  value={this.state.lastname}
                  onChange={(e) => this.setState({ lastname: e.target.value })}
                />
              </Form.Group>

              <Form.Group size="lg" className="form-group" controlId="email">
                <Form.Label style={{ marginBottom: 0 }} >{t("email") + "*"}</Form.Label>
                <Form.Control
                  autoFocus
                  type="text"
                  value={this.state.email}
                  required
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </Form.Group>

              <Form.Group size="lg" className="form-group" controlId="password">
                <Form.Label style={{ marginBottom: 0 }} >{t("password") + "*"}</Form.Label>
                <Form.Control
                  autoFocus
                  type="password"
                  value={this.state.password}
                  required
                  onChange={(e) => this.setState({ password: e.target.value })}
                />
              </Form.Group>

              <Form.Group size="lg" className="form-group" controlId="confirmpassword">
                <Form.Label style={{ marginBottom: 0 }} >{t("password_confirm") + "*"}</Form.Label>
                <Form.Control
                  autoFocus
                  type="password"
                  value={this.state.confirmPassword}
                  required
                  onChange={(e) =>
                    this.setState({ confirmPassword: e.target.value })
                  }
                />
              </Form.Group>


              <Form.Group size="lg" className="form-group" controlId="country">
                <Form.Label style={{ marginBottom: 0 }} >{t("country") + "*"}</Form.Label>
                <Form.Control
                  as="select"
                  required
                  onChange={(e) => this.onCountryChange(e)}


                >
                  {states &&
                    Object.keys(states).map(function (keyName, keyIndex) {
                      return (
                        <option selected={myState === keyName} value={keyName}>
                          {states[keyName]}
                        </option>
                      );
                    })}
                </Form.Control>
              </Form.Group>

              <Form.Group
                size="lg"
                className="position-relative form-group"
                controlId="postcode"
              >
                <Form.Label style={{ marginBottom: 0 }} >{t("postcode") + "*"}</Form.Label>
                <Form.Control
                  required
                  type="text"
                  autoComplete="off"
                  value={this.state.postcodeCity}
                  onFocus={() => this.setState({ openDropdownSearch: true })}
                  onChange={(e) => {
                    this.setState({ postcodeCity: e.target.value, municipalities: [] });
                    // if (e.target.value.length > 2)
                    this.postcodeOffset.current = 0
                    this.getLocationFromPost(e.target.value, myState);
                  }}
                />
                {this.state.municipalities && this.state.openDropdownSearch && (
                  <DropdownSearch
                    results={this.state.municipalities}
                    className="mx-15"
                    handleClick={(id, name) =>
                      this.setState({
                        regTown: id,
                        postcodeCity: name,
                        openDropdownSearch: false,
                      })
                    }
                    fetchData={() => this.getLocationFromPost(this.state.postcodeCity, myState)}
                  />
                )}
              </Form.Group>
              <div className="error-msg">
                {this.state.noDataFoundMsg}
              </div>

              <Form.Group size="lg" className="form-group" controlId="language">
                <Form.Label style={{ marginBottom: 0 }} >{t("language") + "*"}</Form.Label>
                <Form.Control
                  as="select"
                  required
                  onChange={(e) => this.setState({ myLang: e.target.value })}
                  defaultValue={selectedLang ? selectedLang : 8}
                >
                  {languages &&
                    Object.keys(languages).map(function (keyName, keyIndex) {
                      return (
                        <option selected={myLang === keyName} value={keyName}>
                          {languages[keyName]}
                        </option>
                      );
                    })}
                </Form.Control>
              </Form.Group>

              {!this.state.isPromoCodeValid && this.state.inviteCode === "0" && (
                <Form.Group size="lg" className="form-group" controlId="promocode">
                  <Form.Label style={{ marginBottom: 0 }} >{t("promo_code")}</Form.Label>
                  <Form.Control
                    type="text"
                    value={this.state.promocode}
                    onChange={(e) =>
                      this.setState({ promocode: e.target.value })
                    }
                  />
                </Form.Group>
              )}

              <Form.Group size="lg" controlId="agree" className="mb-0">
                <Checkbox
                  text={t("accept_terms_and_privacy")}
                  value={this.state.isGDPR}
                  required={true}
                  className="tc"
                  handleChange={(e) =>
                    this.setState({ isGDPR: !this.state.isGDPR })
                  }
                />
                <p className="termText">
                  <IconLink
                    text={t("terms_of_service")}
                    clear={true}
                    handleClick={() => this.onTermsClick("terms")}
                  />{" "}
                  <span>{t("and")}</span>{" "}
                  <IconLink
                    text={t("privacy_policy")}
                    clear={true}
                    handleClick={() => this.onTermsClick("privacy")}
                  />
                </p>
              </Form.Group>

              <Button
                name="register-submit"
                text={t("register")}
                type="submit"
                className="w-100 mt-30"
              />
            </Form>
          )}

          <IconLink
            link="/login"
            text={t("login_back")}
            clear={true}
            className="w-100 d-block text-center mt-30"
          />

          {/* {this.state.message && (
            <span className="formMessage">{this.state.message}</span>
          )} */}
        </div>
        <ModalSuccess
          show={this.state.successShow}
          close={() => this.setState({ successShow: false })}
        />
        <ModalTerms
          show={this.state.showTermsModal}
          close={() => this.setState({ showTermsModal: false })}
          link={this.state.link}
        />
        <ErrorModal
          show={this.state.showErrorModal}
          close={() => this.setState({ showErrorModal: false })}
          errorMsg={this.state.message}
        />
      </div>
    );
  }
}

const ModalSuccess = ({ show, close }) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Modal centered show={show} onHide={close}>
        <Modal.Body>
          <h2>{t("registrationPopupTitle")}</h2>
          <p>{t("registrationPopupText1")}</p>
          <p>{t("registrationPopupText2")}</p>
          <div className="actions register-actions mt-30">
            <IconLink
              link="/login"
              text={t("login_back")}
              clear={true}
              className=""
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const ErrorModal = ({ show, close, errorMsg }) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Modal centered show={show} onHide={close} className="custom-modal">
        <IconButton
          clear="true"
          className="closeIcon"
          icon="close"
          handleClick={() => close()}
        />
        <div className="text-danger">{t(errorMsg)}</div>
      </Modal>
    </>
  );
};

const ModalTerms = ({ show, close, link }) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Modal centered show={show} onHide={close}>
        <Modal.Body className="custom-modalbody">
          <iframe
            src={link}
            frameBorder={0}
            width={"100%"}
            className={"iframe"}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withTranslation()(Register);
