import React, { createContext, useRef } from "react";
import {SOCKET_URL} from '../../global';
import EVENT_ENUM from '../utils/ChatEvents';
const SocketContext = createContext();

export const useSocketContext = () => React.useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const webScoketRef = useRef(null);

  const setWebSocketConnection = (socket) => { 
    console.log(socket,"sdsd");
    webScoketRef.current = socket;
  }; 

  

  const contextValue = { webScoketRef, setWebSocketConnection};

  return (
    <SocketContext.Provider value={contextValue}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketContext;
