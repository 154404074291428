import { Card } from "components/shared/card";
import ReactHtmlParser from "html-react-parser";
import { GroupSettingsData } from "lib/types/groupTypes";
import linkifyHtml from "linkify-html";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InfoTabFiles } from "./InfoTabFiles";
import * as Styled from "./style";

type Props = {
  data: GroupSettingsData | null;
};

export const InfoTab = ({ data }: Props) => {
  const { t } = useTranslation();
  const [website, setWebsite] = useState<string>("");

  useEffect(() => {
    if (data?.LGP_web) {
      if (data.LGP_web.startsWith("http://") || data.LGP_web.startsWith("https://")) {
        setWebsite(data.LGP_web);
      } else {
        setWebsite("https://" + data.LGP_web);
      }
    }
  }, [data?.LGP_web]);

  return (
    <>
      <Card>
        <p>
          {data?.LGP_about && ReactHtmlParser(
            linkifyHtml(data?.LGP_about, {
              nl2br: true,
              target: {
                url: "_blank",
              },
            }),
          )}
        </p>
        <Styled.InfoContactList>
          {data?.LGP_contact && <Styled.InfoContactListItem
            className={data?.LGP_contact ? "" : "disabled"}
            href={"mailto:" + data?.LGP_contact}
          >
            <span>
              <i className='material-icons material-symbols-outlined'>mail</i>
            </span>
            {data?.LGP_contact || t("common.not-set")}
          </Styled.InfoContactListItem>}

          {data?.GrpLink && <Styled.InfoContactListItem
            className={data?.GrpLink ? "" : "disabled"}
            href={data?.GrpLink}
            target="_blank"
          >
            <span>
              <i className='material-icons material-symbols-outlined'>link</i>
            </span>
            {data?.GrpLink || t("common.not-set")}
          </Styled.InfoContactListItem>}

          {data?.LGP_phone && <Styled.InfoContactListItem
            className={data?.LGP_phone ? "" : "disabled"}
            href={"tel:" + data?.LGP_phone}
          >
            <span>
              <i className='material-icons material-symbols-outlined'>call</i>
            </span>
            {data?.LGP_phone || t("common.not-set")}
          </Styled.InfoContactListItem>}

          {data?.LGP_web && <Styled.InfoContactListItem
            className={website ? "" : "disabled"}
            href={website || "#"}
            target='_blank'
          >
            <span>
              <i className='material-icons material-symbols-outlined'>public</i>
            </span>
            {data?.LGP_web || t("common.not-set")}
          </Styled.InfoContactListItem>}
        </Styled.InfoContactList>
      </Card>
      <Card>
        <InfoTabFiles data={data} />
      </Card>
    </>
  );
};
