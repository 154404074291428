import { downloadChatFiles } from "api/common";
import { t } from "i18next";
import { PostData } from "lib/types/postTypes";
import { Dropdown } from "react-bootstrap";
import Button from "../../elements/buttons/Button";

type PostContextMenuProps = {
  data: PostData;
  token: string;
  isMember: boolean;
  analysePostAction: (postID: number) => void;
  sharePostAction: (postID: number) => void;
  editPostAction: (post: any) => void;
  deletePostAction: (postID: number) => void;
  reportPostAction: (postId: number, message: string) => void;
};

const PostContextMenu = (props: PostContextMenuProps) => {
  const isAdmin = props.data?.LCP_admin_post === "y";
  const isSuperAdmin = props.data?.superAdmin === "1";

  const onSaveClick = () => {
    downloadChatFiles(props.token, props.data.loci_postID, null, "post");
  };

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          className='btn btn-clear'
          disabled={props.isMember === false}
          style={{ color: "#C1CCA2" }}
        >
          <i className='material-icons ml-5' style={{ transform: "rotate(90deg)" }}>
            more_vert
          </i>
        </Dropdown.Toggle>

        <Dropdown.Menu rootCloseEvent={"click"}>
          <Button
            text={t("analyse_menu_option")}
            clear='true'
            handleClick={() => props.analysePostAction(props.data.loci_postID as number)}
          />
          {props.data.GroupAdminShare !== undefined && props.data.GroupAdminShare !== null && (
            <Button
              text={t("share")}
              clear='true'
              handleClick={() => props.sharePostAction(props.data.loci_postID as number)}
            />
          )}

          {(props.data.LCP_media.length > 0 || props.data.LCP_docs.length > 0) && (
            <Button text={t("save")} icon='close' clear='true' handleClick={() => onSaveClick()} />
          )}

          {props.data.my === "n" && !isAdmin ? (
            <Button
              text={t("report_post")}
              icon='close'
              clear='true'
              handleClick={() => props.reportPostAction(props.data.loci_postID as number, "")}
            />
          ) : props.data.my === "n" && isAdmin ? (
            <>
              <Button
                text={t("report_post")}
                icon='close'
                clear='true'
                handleClick={() => props.reportPostAction(props.data.loci_postID as number, "")}
              />
              {isSuperAdmin && (
                <Button
                  text={t("delete_menu_option")}
                  clear='true'
                  handleClick={() => {
                    document.body.click();
                    props.deletePostAction(props.data.loci_postID as number);
                  }}
                />
              )}
            </>
          ) : props.data.OrgGroupId && isSuperAdmin ? (
            <>
              <Button
                text={t("delete_menu_option")}
                clear='true'
                handleClick={() => {
                  document.body.click();
                  props.deletePostAction(props.data.loci_postID as number);
                }}
              />
            </>
          ) : (
            <>
              <Button
                text={t("edit_post_option")}
                clear='true'
                handleClick={() => props.editPostAction(props.data)}
              />

              <Button
                text={t("delete_menu_option")}
                clear='true'
                handleClick={() => {
                  document.body.click();
                  props.deletePostAction(props.data.loci_postID as number);
                }}
              />
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default PostContextMenu;
