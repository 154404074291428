import React, { useEffect, useState ,useRef } from "react";
import PropTypes from "prop-types";
import { Form, Spinner } from "react-bootstrap";
import FileList from "../../upload";
import { useTranslation } from "react-i18next";
import "./create.scss";
import { getGroupAdmins } from "../../../api/common";
import UserCard from "../../elements/cards/UserCard";
import Loader from "../../../components/posts/single-post/PostLoader/postLoader.js";
import IconButton from "../../elements/buttons/IconButton";
import Button from "../../elements/buttons/Button";
import GlobalConfirmDialogue from "../../commons/GlobalConfirmDialogue";
import BackButton from "../../commons/BackButton";
import {
  useNavigate,
  useLocation,
} from "react-router-dom";
import { createChatGroup } from "../../../api/chat";
import ToastModal from "../../commons/ToastModal";
import IndicationDialogue from "../../commons/indicationDialouge";
import { useAppDispatch, useAppSelector } from "lib/store";
import * as GlobalStore from 'lib/store/global';

const Create = (props) => {
  const [avatar, setGroupAvatar] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [groupCreated, setGroupCreated] = useState(false);
  let token = props.token;
  const query = useAppSelector(GlobalStore.selectQuery);
  const dispatch = useAppDispatch();
  const [shouldSelect, setShouldSelect] = useState(false);
  const [scroolLoading, setScrollLoading] = useState(false)


  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const history = useNavigate();

  const [allUsers, setAllUsers] = useState(localStorage.getItem("ga") ?? []);
  const { t } = useTranslation(); 
  const page = useRef(1); // Use useRef to keep track of the current page
  const myRef = useRef(null)  
  const searchRef = useRef(null)

  const setAvatar = (avatar) => {
    setGroupAvatar(avatar);
  };

  useEffect(() => { 
    page.current=1
    searchRef.current=query

    getInboxList(); 
    const divElement = myRef.current; 

    if(divElement){
    myRef.current.scrollTop=0 
    }
  }, [query]);

  const getInboxList = () => {
    getGroupAdmins(props.token, searchRef.current,20, page.current).then((res) => {
      if (res.data.row) {
        const data = res.data.row;
        let modifiedData = [];
        let userDetails = JSON.parse(localStorage.getItem("profile"));
        if (userDetails) {
          console.log(userDetails.userId);
          modifiedData = data.filter(
            (me) => me.FK_userID !== userDetails.userId
          );
        }
        console.log(modifiedData); 
        if(page.current != 1){
          setAllUsers((prevData) => [...prevData, ...modifiedData]) 
          }else{
            setAllUsers([...modifiedData])
          }
        setLoading(false); 
        setScrollLoading(false)
      }else{
        setScrollLoading(false)
      }
    });
  };

  const onUserClick = (a, b, x) => {
    if (b === "div") {
      if (selectedUsers?.length === 0) setSelectedUsers([...selectedUsers, x]);
      else {
        if (!selectedUsers?.find((me) => me.FK_userID === x.FK_userID)) {
          setSelectedUsers([...selectedUsers, x]);
        } else {
          let result = selectedUsers?.filter(
            (me) => me.FK_userID !== x.FK_userID
          );
          setSelectedUsers(result);
        }
      }
    }
  };

  const onRemoveIcon = (id) => {
    let result = selectedUsers.filter((me) => me.FK_userID !== id);
    console.log(result);
    setSelectedUsers([...result]);
  };

  const redirectToPage = (id) => {
    history({
      pathname: `/group-chat/${id}`,
    });
  };


  const redirectToGroupChat = () => {
    setShowModal(false);

    setLoading(true);
    let ids = [];
    selectedUsers?.map((user) => {
      ids?.push(user?.FK_userID);
    });
    createChatGroup(token, groupName, ids, avatar[0])
      .then((res) => {
        if (res.data.OK) {
          dispatch(GlobalStore.search(""));
          setGroupCreated(true);
          setTimeout(function() { redirectToPage(res.data.LC_room_id); },2000);
        }
      })
      .finally(() => setLoading(false));
  };  

  const handleScroll = async () => {
    // Check if the user has scrolled to the bottom of the page 
    try {

      if (
        myRef.current.clientHeight + myRef.current.scrollTop + 1 >
        myRef.current.scrollHeight
      ) { 
        page.current += 1; // Increment the page for the next API call
        setScrollLoading(true)
        getInboxList(); // Call fetchData when the user reaches the bottom
      }
    } catch (error) {
      console.log(error, "ids");
    }

  };

  useEffect(() => {
    // Attach the scroll event listener when the component mounts
    // console.log(window.innerHeight, document.documentElement.scrollTop, document.documentElement.scrollHeight, "ids");

    const divElement = myRef.current;

    if (divElement) {
      console.log("ids");
      divElement.addEventListener('scroll', handleScroll);
      console.log(myRef, "ids");
    }

    // Remove the event listener when the component unmounts
    return () => {
      if (divElement) {
        divElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [myRef.current]);

  return (
    <>
      <div className="container">
        <div className="topHeader">
          {/* <div className="back-btn-cont">
            <BackButton />
          </div> */}
          {
            <div className="btn-cont">
              <Button
                disabled={groupName === ""}
                className="confirm-btn"
                text={t("confirm")}
                handleClick={() =>
                  selectedUsers?.length === 0
                    ? setShouldSelect(true)
                    : setShowModal(true)
                }
              />
            </div>
          }
        </div>

        <div className="card">
          <Form className="create-chat-group">
            <div className="row-form-control">
              <div className="avatar-box">
                <FileList
                  id={"mediaUpload"}
                  fileTypes={".png, .jpg, .jpeg"}
                  setFiles={setAvatar}
                  files={avatar}
                  icon="add_a_photo"
                  className="groupMediaUpload"
                  single={true}
                  previews={true}
                />
              </div>
              <div className="flex-grow-1">
                <Form.Group size="lg" controlId="groupName">
                  <Form.Label>{t("group-name")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("group-name-placeholder")}
                    required
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value.trimStart())}
                  />
                </Form.Group>
              </div>
            </div>
          </Form>
          <hr />

          <div>
            {selectedUsers?.length < 1 ? (
              <h5 className="addParty">{t("add-participants")}</h5>
            ) : (
              <>
                <h5>
                  {selectedUsers?.length +
                    " " +
                    (selectedUsers?.length > 1
                      ? t("participants-added")
                      : t("participant-added"))}
                </h5>
                <div className="added-users">
                  {selectedUsers.map((me) => (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className="img-cont">
                        <img src={me?.USE_pic ?? "/images/profile.png"} />

                        <i
                          className="material-icons material-icons"
                          onClick={() => onRemoveIcon(me.FK_userID)}
                        >
                          {"disabled_by_default"}
                        </i>
                      </div>
                      <span
                        style={{ textAlign: "center" }}
                        className="imageText"
                      >
                        {me?.USE_firstname}
                      </span>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
          <hr />

          <div className="list" ref={myRef} >
            {loading ? (
              <div className="sending-overlay">
                <div class="loader">
                  <Spinner
                    animation="border"
                    role="status"
                    color="#00395c"
                  ></Spinner>
                </div>
              </div>
            ) : (
              allUsers?.map((x, i) => (
                <UserCard
                  item={x}
                  className={
                    x.LCM_read === "y" ? "read cursor" : "unread cursor"
                  }
                  handleClick={(e, clickedOn) => onUserClick(e, clickedOn, x)}
                >
                  {selectedUsers.some((me) => me.FK_userID === x.FK_userID) && (
                    <i className="material-icons material-icons primary">
                      {"check_circle"}
                    </i>
                  )}
                </UserCard>
              ))
            )} 
           
          </div>
          {scroolLoading && (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '20px', position: 'absolute', bottom: '0px  ' }}
                ><Spinner /></div>)}
        </div>
      </div>
      {groupCreated && <ToastModal msg={t("chat-group-created")} />}
      {showModal && (
        <GlobalConfirmDialogue
          show={showModal}
          title={"create-new-group-chat"}
          content={"new-group-chat-confirm-msg"}
          name={groupName}
          closeModal={() => setShowModal(false)}
          onSubmit={() => redirectToGroupChat()}
        />
      )}

      {shouldSelect && (
        <IndicationDialogue
          show={shouldSelect}
          title={"please_select_participants"}
          name={groupName}
          onSubmit={() => setShouldSelect(false)}
        />
      )}

      {loading && (
        <div className="sending-overlay">
          <div class="loader">
            <Spinner animation="border" role="status" color="#00395c"></Spinner>
          </div>
        </div>
      )}
    </>
  );
};

export default Create;
