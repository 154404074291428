import AttachmentIcon from "@mui/icons-material/Attachment";
import DownloadIcon from "@mui/icons-material/Download";
import { Box, Chip, ListItem } from "@mui/material";
import { downloadSingleFileFromPost } from "api/common";
import { PostDoc } from "lib/types/postTypes";

type PostDocumentsProps = {
  token: string;
  documents: PostDoc[];
};

const PostDocuments = (props: PostDocumentsProps) => {
  const handleDownload = (doc: PostDoc) => {
    downloadSingleFileFromPost(props.token, doc);
  };

  return (
    <Box
      sx={{
        display: "inline-flex",
        justifyContent: "center",
        flexWrap: "wrap",
        listStyle: "none",
        p: "0 0 16px 0",
        m: "0",
        gap: "15px",
        WebkitFlexWrap: "wrap",
      }}
      component='ul'
    >
      {props.documents.map((document) => (
        <ListItem
          sx={{ width: "unset", paddingLeft: 0, paddingRight: 0 }}
          key={document.loci_docID}
        >
          <Chip
            deleteIcon={<DownloadIcon />}
            onClick={() => handleDownload(document)}
            onDelete={() => handleDownload(document)}
            icon={<AttachmentIcon />}
            label={document.doc}
          />
        </ListItem>
      ))}
    </Box>
  );
};

export default PostDocuments;
