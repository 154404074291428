import { XIcon } from "components/icons/X";
import styled, { css } from "styled-components";

export const Modal = styled.div<{ $isOpen: boolean }>`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  z-index: 1052;
  transition: ${({ theme }) => theme.transition.default};
  padding: 20px 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  ${({ $isOpen }) =>
    $isOpen &&
    css`
      opacity: 1;
      visibility: visible;
      overflow: auto;
    `}
`;
export const ModalShadow = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
`;
export const CloseButton = styled(XIcon)`
  width: 30px;
  height: 30px;
  position: absolute;
  right: 24px;
  top: 28px;
  border-radius: 100%;
  background: rgba(118, 118, 128, 0.12);
  color: #3c3c43;
  padding: 8px;
  opacity: 0.6;
  cursor: pointer;
`;
export const ModalContent = styled.div<{ $rounded?: boolean }>`
  max-width: 800px;
  min-width: 400px;
  position: relative;
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacing["12"]};
  background-color: ${({ theme }) => theme.colors.white};
  ${({ $rounded }) =>
    $rounded &&
    css`
      border-radius: 28px;
    `}
`;
