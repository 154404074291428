import React, { useState, useEffect, useContext } from "react";
import "rc-slider/assets/index.css";
import "./Groups.scss";
import Feed from "../feed/index.tsx";
import { withRouter } from "../customNavigate/withRouter";
import { getGroupPermissions } from "../../api/groups";
import { useAuthState } from "lib/context/authContext";
import { useTranslation } from "react-i18next";
import GlobalStateContext from "lib/context/global-state/GlobalStateContext";
import { Spinner } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";

const GroupFeed = ({ ...props }) => {
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [groupId, setGroupId] = useState(null);
  const [memberError, setMemberError] = useState(false);
  const userDetails = useAuthState();
  const { t, i18n } = useTranslation();
  const [groupPerData, setGroupPerData] = useState(null)
  const [token, setToken] = useState(null);
  const [isMember, setIsMember] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    setGroupId(props.match.params.id);
    setToken(userDetails.token ?? globalState.embeddedToken);

    return () => {
      setGlobalState({ ...globalState, embedded: false, embeddedToken: null });
    }
  }, []);

  useEffect(() => {
    if (userDetails && groupId) {
      getData(props.match.params.id);
    }
  }, [userDetails, groupId]);

  useEffect(() => {
    setGroupId(props.match.params.id);
  }, [props.match.params.id]);

  const getData = async (groupId) => {
    setLoading(true)
    await getGroupPermissions(token, groupId).then(
      (res) => {
        if (res.data.OK) {
          var resData = res.data.data;
          setGroupPerData(resData)
          if (resData.member == 0 && !globalState.embedded) {
            setMemberError(true);
          }

          setIsMember(resData.member === '1');
          // setPerms(groupPerms);
        } else {
          setMemberError(true);
        }
      }
    ).finally(_ => setLoading(false));
  };

  return (
    <>
      {
        loading ? 
          <div class="loader">
            <Spinner animation="border" role="status" color="#00395c">
              <span className="sr-only">{t("loading")}...</span>
            </Spinner>
          </div> :
          <div
            className={
              memberError
                ? "schlumpf-content-narrow showAlert"
                : "schlumpf-content-narrow"
            }
          >
            {(memberError && !searchParams.get('redirectFrom')) && <p className="alertMeg">{t("not_group_member")}</p>}
            {groupId && (
              <Feed
                {...props}
                token={token}
                groupId={groupId}
                isGroupMember={!memberError}
                groupPerData={groupPerData}
                publicPosts={!userDetails.token || !isMember}
              />
            )}
          </div>
      }
    </>
  );
};

export default withRouter(GroupFeed);
