import React, { Suspense, useContext, useEffect, useState } from "react";
import Login from "./views/login/Login";
import Home from "./views/home/Home";
import Footer from "./components/footer";

import Navbar from "./components/navbar";

import { useNavigate, useParams, Route, useLocation, Routes } from "react-router-dom";
import { SocketProvider } from "./lib/context/socketContext";

import "./App.scss";
import { useAuthDispatch, useAuthState } from "./lib/context/authContext";
import ForgotPassword from "./views/login/ForgotPassword";
import Register from "./views/login/Register";
import FAQAndSupport from "./components/FAQAndSupport";
import OverUs from "./components/OverUs";
import Gdpr from "./components/gdpr";
import { routes } from "./views/home/routes";
import UnauthPost from "./components/posts/view-post/UnauthPost";
import Loader from "./components/posts/single-post/PostLoader/postLoader.js";
import { checkGroupCode,checkGroupAlias } from "./api/groups";
import { automaticLogin } from "./api/user";
import { completeLoginWithToken, logoutUser } from "./lib/context/authActions";
import FirebaseConfig, {
  getFirebaseToken,
  onMessageListener,
} from "./firebase/firebase";
import NotificationModal from "./components/commons/NotificationModal";
import {
  markReadNotification,
  markReadNotificationMail,
  setGCMToken,
} from "./api/common"; 
import { HelmetProvider } from 'react-helmet-async';
import Redirect from "./components/customNavigate/redirect";
import PublicFeed from "./components/posts/PublicFeed";
import moment from "moment"; 
import 'moment/locale/de'; // Import German locale
import 'moment/locale/hu'; // Import Dutch locale
import GlobalStateContext from "./lib/context/global-state/GlobalStateContext.js";
import SingleGroup from "./components/single-group";
import { useAppDispatch } from "lib/store";
import * as GlobalStore from 'lib/store/global';

const ROUTE_CODE_INVITATION_LINK = "Gj9J";
const ROUTE_CODE_EMBEDDED_GROUP = "Gx9J";
const ROUTE_CODE_REGISTER_INVITATION_LINK = "5tz7";
const ROUTE_CODE_MAIL_LINK = "LP87";

function findRouteByPath(path) {
  // console.log({ path });
  let data = JSON.parse(localStorage.getItem("user"));
  return routes.find((route) => {
    // console.log(route.path, path, route.path === path, data?.TMBP_ACC_TOKEN);
    if (route.path === path) {
      // console.log({ route },userDetails?.token);
      return route;
    } else if (
      route.path.split("/")[1] === path.split("/")[1] &&
      data?.TMBP_ACC_TOKEN
    ) {
      return path;
    }
  });
}

function App(props) {
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  //  const { t, i18n } = useTranslation();  

  const language = localStorage.getItem("i18nextLng");  
   console.log(language,"rinku");
  if (language === 'de') {
    moment.locale('de'); // Set the locale to German
  } else if (language === 'hu') {
    moment.locale('hu'); // Set the locale to Dutch
  } else if(language ==='cs'){
    moment.locale('cs'); // Set the locale to English (default)
  }else{
    moment.locale('en');
  }


  const userDetails = useAuthState();
  const params = useParams();
  const history = useNavigate();
  const dispatch = useAuthDispatch();
  const storeDispatch = useAppDispatch();

  const location = useLocation();
  const [isMenu, setMenu] = useState(false);
  const [isTokenFound, setTokenFound] = useState("");

  const [notification, setNotification] = useState({
    show: false,
    title: "",
    body: "",
    link: "",
  });

  if (process.env.NODE_ENV !== "development") console.log = () => { };

  useEffect(() => {
    let userAgent = navigator.userAgent; 
    if (userAgent.match(/firefox|fxios/i) && !isTokenFound) {
      document.addEventListener("click", askForNotification);
    }
    //     else if(userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)|| userAgent.match(/safari/i)){
    //       console.log('2');

    // return true
    //     }
    //     else if(/(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent)){
    //       console.log('3');

    //       alert("webview");
    //       return true
    //     }
    else {
      askForNotification();
    }
    return () => document.removeEventListener("click", askForNotification);
  }, []);

  const askForNotification = () => {
    try {
      if (!window?.Notification || !window?.Notification?.requestPermission)
        return;
      window?.Notification?.requestPermission().then(function (status) {
        if (status === "denied") {
          //
        } else if (status === "granted") {
          getFirebaseToken(setTokenFound);

          //
        }
      });
    } catch (e) {
      console.log("e", e);
      alert(e);
    }
  };

  useEffect(() => {
    handleNavigation();
  }, []);

  useEffect(() => {
    if (isTokenFound !== "") {
      localStorage.removeItem("gcmtoken");
      localStorage.setItem("gcmtoken", isTokenFound);
      registerGCMToken(); 
          }
  }, [isTokenFound]);

  async function registerGCMToken() {
    try {
      const response = await setGCMToken(userDetails.token, isTokenFound);
      if (response.data.OK) console.log("success");
    } catch (e) {
      console.log(e);
    }
  }

    /**
     * get the group id from the group alias name
     */
    function getGroupIdFromAlias(groupAlias)
    {
        let mytoken = null;

        if (userDetails.token) {
            let mytoken = userDetails.token;    
        }

        checkGroupAlias(mytoken,groupAlias).then((res) => {
            if (res.data.OK) {
                const groupId = res.data.OK;
    
                console.log(userDetails.token);
                if (userDetails.token) {
                    return history(`/group/${groupId}/about`);
                } else {
                    return history(`/group/unauth/${groupId}/about`);
                }
            }

            if (res.data.ER) {
                alert(res.data.ER);
            } else {
                history("/login");
            }
            
        });
    } // end function getGroupIdFromAlias

  function getGroupIdFromCode(groupCode, onlyView, token) {
    checkGroupCode(null, groupCode, onlyView).then((res) => {
      if (res.data.OK) {
        console.log('checkGroupCode', res.data);
        const groupId = res.data.OK;
        const encodeInviteCode = encodeURIComponent(groupCode);
        let data = JSON.parse(localStorage.getItem("user"));
        if (onlyView === true) {
          document.getElementsByTagName("body")[0].className = 'embedded';
          setGlobalState({...globalState, embedded: true, embeddedToken: token});
          return history(`/group/unauth/${groupId}/about?redirectFrom=embedded`);
        }

        setGlobalState({...globalState, embedded: false, embeddedToken: null});
        if (userDetails.token || data?.TMBP_ACC_TOKEN) {
          return history(`/group/${groupId}/about?gid=${encodeInviteCode}&redirectFrom=invite`);
        } else {
          return history(`/group/unauth/${groupId}/about?gid=${encodeInviteCode}&redirectFrom=invite`);
        }
      }

      if (res.data.ER === "Die Einladung wurde bereits verwendet.") {
        // alert(t("invite_code_already_used"))
        alert(res.data.ER);
      } else {
        history("/login");
      }
    });
  }    

  async function doDirectLogin(link) {
    // await logoutUser(dispatch);
    automaticLogin(link).then((res) => {
      if (res.data.OK) {
        completeLoginWithToken(dispatch, res.data.TMBP_ACC_TOKEN, res.data);
        storeDispatch(GlobalStore.updateNotificationCount(""));

        return history("/");
      } else {
        return history("/login");
      }
    });
  }

  async function markMailAsRead(id) {
    try {
      const response = await markReadNotificationMail(userDetails.token, id);

      if (response.data.OK) storeDispatch(GlobalStore.updateNotificationCount(""));
    } catch (e) {
      console.log(e);
    }
  }
  const arr = [
    "Pcom",
    "Plike",
    "Plikegrp",
    "Pcomgrp",
    "Pfavorite",
    "Preg",
    "Pintern",
  ];

  async function redirectMailToPage(item) {
    console.log("chatL", item); 

    await markMailAsRead(item.notid);
    if ((item.type, arr.includes(item.type) && !userDetails.token)) {
      return history("/post/unauth/" + item.postId);
    } else if (
      (item.type === "Plike" && item.intern === "y") ||
      (item.type === "Pfollow" && item.intern === "y")
    )
      return history(`/post/${item.postId}?g=${item.groupId}`);
    else {
      switch (item.type) {
        case "Pgrpinvite": 
        console.log("lll");
          return history("/notifications", {
            state: { noti: true, itm: item }, }
          );
        case "Pgrpjoinpending":
          return history(`/group/${item.groupId}/requests`);
        case "Pgrpjoin":
          return history(`/group/${item.groupId}/members`);
        case "Pgrpadminpostnotify":
          return history(`/group/${item.groupId}`);
        case "Pgrpjoinaccept":
          return history(`/group/${item.groupId}`);
        case "Pfollow":
          return history(`/post/${item.postId}`);
        case "Pmail":
          return history(`/chat/${params.get("userId")}`);
        case "Pcom":
          return history(`/post/${item.postId}`);

        case "Plikegrp":
          return history(`/post/${item.postId}?g=${item.groupId}`);
        case "Plike && ":
          return history(`/post/${item.postId}?g=${item.groupId}`);
        case "Pcomgrp":
          return history(`/post/${item.postId}?g=${item.groupId}`);
        case "Pfavorite":
          return history(`/post/${item.postId}`);
        case "Preg":
          return history(`/post/${item.postId}`);
        case "Plike":
          return history(`/post/${item.postId}`);

        case "Pintern":
          return history(`/post/${item.postId}?g=${item.incus}`);

        default:
          break;
      }
    }
  }

  function redirectToPost() {
    let string = location.pathname;
    let unauthPost = string.includes("unauth");
    let stringWithoutId = string.split("/");
    let id = stringWithoutId.pop();
    let path = stringWithoutId.join("/");
    if (path.includes("/post")) {
      if (!unauthPost) return history("/post/unauth/" + id);
      else return null;
    }
  }

  async function handleNavigation() {

    
    if (location.pathname.includes('/g/')) {
        let urlSplit = location.pathname.split("/");
        let GroupAlias = urlSplit[urlSplit.length - 1];

        return await getGroupIdFromAlias(GroupAlias);

    } else if (location.pathname.includes(ROUTE_CODE_INVITATION_LINK)) {
      const groupCode = location.pathname.split(",,")[1].split(",")[0];
      localStorage.setItem("groupCode", groupCode);
      return await getGroupIdFromCode(groupCode);
    } else if (location.pathname.includes(ROUTE_CODE_EMBEDDED_GROUP)) {
      let urlSplit = location.pathname.split(",");
      const anonymToken = urlSplit[1];
      const groupCode   = urlSplit[2];
      return await getGroupIdFromCode(groupCode, true, anonymToken);
    } else if (location.pathname.includes(ROUTE_CODE_REGISTER_INVITATION_LINK)) {
      const registrationLink = location.pathname;
      let link = `https://locidev.tmbp.at${registrationLink}`;
      return await doDirectLogin(link);
    } else if (location.pathname.includes(ROUTE_CODE_MAIL_LINK)) {
      const currentPath = location.pathname;
      const encodedUrl = currentPath.split(",")[2];

      const decodedUrl = atob(encodedUrl);

      let data = decodedUrl
        .split("&")
        .reduce((a, v) => ({ ...a, [v.split("=")[0]]: v.split("=")[1] }), {});

      redirectMailToPage(data);
    } else if (findRouteByPath(location.pathname)) {
      if (findRouteByPath(location.pathname).noTokenAccess) {
        return <></>;
      }
    } else {
      let string = location.pathname;

      let unauthPost = string.includes("unauth");
      let stringWithoutId = string.split("/");
      let id = stringWithoutId.pop();
      let path = stringWithoutId.join("/");

      if (string.includes("/group") && string.includes("/about") ) {
        const groupID = stringWithoutId[2];
        if (!unauthPost && groupID) return history(`/group/unauth/${groupID}/about`);
        else return null;
      } else if (path.includes("/post")) {
        if (!unauthPost) return history("/post/unauth/" + id);
        else return null;
      } else {
        console.log("else case login", location.pathname);
        // alert("login")
        return history("/login",{replace:true});
      }
    }
  }

  const handleNotification = (payload) => {
    console.log("chat", payload);

    setNotification({
      show: true,
      title: payload.notification.title,
      body: payload.notification.body,
      link: payload?.fcmOptions?.link,
    });
    const notificationCount = JSON.parse(payload.data["gcm.notification.data"]);

    const count = notificationCount.badge;
    storeDispatch(GlobalStore.updateNotificationCount(count));
  };

  useEffect(() => {
    const listener = onMessageListener(handleNotification);
    setTimeout(() => {
      setNotification({
        show: false,
        title: "",
        body: "",
        link: "",
      });
    }, 5000);
    return () => {
      listener();
    };
  }, [notification?.show]);

  const onNotificationClick = async () => {
    let link = notification.link;
    console.log("chat", link);
    if (link.includes("#")) {
      if (link.includes("group-chat")) {
        let chatId = link.split("/")[3];
        let notId = link.split("/")[4];
        await markMailAsRead(notId);

        return history("/group-chat/" + chatId);
      }
      else {
        let chatId = link.split("/")[3];
        let notId = link.split("/")[4];
        await markMailAsRead(notId);

        return history("/chat/" + chatId);
      }
    } else {
      const encodedUrl = link.split(",")[2];
      const decodedUrl = atob(encodedUrl);
      console.log("Decode base64 URL=> ", decodedUrl);
      let data = decodedUrl
        .split("&")
        .reduce((a, v) => ({ ...a, [v.split("=")[0]]: v.split("=")[1] }), {});
      setNotification({
        show: false,
        title: "",
        body: "",
        link: "",
      });
      redirectMailToPage(data);
    }
  };

  //  document.title = i18n.t("app_title");

  return ( 
    <HelmetProvider>
    <div className="App">
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={!Boolean(userDetails.token) ? null : (
            
              <SocketProvider>
                <Home token={userDetails?.token} profile={userDetails?.profile} />
              </SocketProvider>
            
          )}>
            <Route exact path="/" element={<PublicFeed token={userDetails?.token} profile={userDetails?.profile} />} />
             {!Boolean(userDetails.token) ? null :routes.map((route, i) => (
            <Route path={route.path} key={i} exact element={<route.component
              token={userDetails?.token}
              profile={userDetails?.profile}
              routeData={route?.props}
            />} />
          ))}
          </Route>
         
          <Route path="/login" element={Boolean(userDetails.token) ? (
              <Redirect to={{ pathname: "/" }} />
            ) : (
              <>
                <Navbar />
                <Login />
              </>
            )} >
            
          </Route>

          <Route path="/reset-password" element={
            <>
              <Navbar />
            <ForgotPassword />
            </>
            } >
            
          </Route>

          <Route path="/register" element={
            <> 
             <Navbar />
            <Register />
            </>
          } >
           
          </Route> 

          {/* If not loggedIn, these routes are loaded here, without navBarActions, otherwise via Home component*/}
          {console.log(!Boolean(userDetails.token),isMenu,"ddd")}
          {!Boolean(userDetails.token) && (
            <>
              <Route path="/terms-and-conditions" element={
                <>
                 <Navbar
                 token={userDetails?.token}
                 isMenu={isMenu}
                 setMenu={setMenu}
               />
              <Gdpr routeData={{ title: "Terms and Conditions" }} /> 
               </>
              } >
                
              </Route>

              <Route path="/privacy-policy" element={
                <> 
                 <Navbar
                  token={userDetails?.token}
                  isMenu={isMenu}
                  setMenu={setMenu}
                />
                <Gdpr routeData={{ title: "Privacy Policy" }} />
                </>
              } >
               
              </Route>

              <Route path="/imprint" 
               element={
                <> 
                 <Navbar
                  token={userDetails?.token}
                  isMenu={isMenu}
                  setMenu={setMenu}
                />
                <Gdpr routeData={{ title: "Imprint" }} />
                </>
               } >
               
              </Route>

              <Route path="/FAQAndSupport" element={
                <> 
                 <Navbar
                  token={userDetails?.token}
                  isMenu={isMenu}
                  setMenu={setMenu}
                />
                <FAQAndSupport />
                </>
              } >
               
              </Route>

              <Route path="/info" element={
                <> 
                 <Navbar
                  token={userDetails?.token}
                  isMenu={isMenu}
                  setMenu={setMenu}
                />
                <OverUs />
                </>
              } >
               
              </Route>

              <Route path="/post/unauth/:id" element={ <UnauthPost /> } >
                {/* <Navbar token={userDetails?.token} isMenu={isMenu} setMenu={setMenu} /> */}
               
              </Route>
              <Route path="/group/unauth/:id/about" element={ <SingleGroup />} />

            </>
          )}


        </Routes>

        {
          !globalState.embedded && <Footer />
        }
      </Suspense>
      {/* <NotificationModal title={'ss'} body={'sa'}/> */}

      {notification.show && (
        <NotificationModal
          title={notification.title}
          body={notification.body}
          onNotificationClick={onNotificationClick}
        />
      )}
    </div> 
    </HelmetProvider>
  );
}

export default App;
//export default withTranslation()(App);
