import React, { Component } from 'react'
import IconButton from '../elements/buttons/IconButton';

class Upload extends Component {

  handleChange(event) {
    this.setState({ file: event.target.files })
  }
 
  
  render() {
    return (
      <>
      <label htmlFor={this.props.id} className={"label-upload clickable " + (this.props.text ? "bordered" : "")}>
        <i className="material-icons green clickable">{this.props.icon}</i>
        {
        this.props.text &&
        <span>{this.props.text}</span>
        }
      </label>
      <input
        id={this.props.id}
        className="d-none"
        type={"file"}
        accept={this.props.accept}
        multiple={!this.props.single}
        onChange={this.props.handleUpload}
      />

      
      </>
       
    )
  }

}

export default Upload;